import React from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  Text,
} from '@chakra-ui/react';

export const OnlineLinkPopOverInfo: React.FC<{}> = ({ children }) => (
  <Popover trigger="hover">
    <PopoverTrigger>{children}</PopoverTrigger>
    <PopoverContent zIndex={4}>
      <PopoverArrow />
      <PopoverHeader>⚠️ ⚠ ️⚠️</PopoverHeader>
      <PopoverCloseButton />
      <PopoverBody>
        <Text mt={3}>Due to our current situation, some sessions will be online.</Text>
      </PopoverBody>
    </PopoverContent>
  </Popover>
);
