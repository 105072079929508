import { gql } from 'apollo-boost';

export const SEND_FORGOT_PASSWORD_LINK = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($data: ChangePasswordInput!) {
    changePassword(data: $data) {
      username
    }
  }
`;
