import React from 'react';
import {
  Flex,
  Text,
  BoxProps,
  Input,
  Button,
  Box,
  Divider,
  Stack,
  Avatar,
  FormControl,
  FormErrorMessage,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
} from '@chakra-ui/react';
import { FiSend } from 'react-icons/fi';
import { FaEllipsisV } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as yup from 'yup';

import { ViewMeetingQuery } from '../generated/graphql';
import { useUser } from '../contexts';

type Props = {
  comments: ViewMeetingQuery['viewMeeting']['comments'];
  onAddComment: (text: string, parentId?: number) => Promise<any>;
  onDeleteComment: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      content: any;
    }>
  >;
};

const getName = (user: any) => {
  if (user.firstName || user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }
  return user.userId;
};

const renderComments = (comments: ViewMeetingQuery['viewMeeting']['comments']) => {
  //@ts-ignore
  return comments.reduce((acc, next) => {
    const comment = {
      id: next.id,
      content: next.content,
      profileImage: next.profileImage,
      fullName: getName(next),
      userId: next.userId,
    };

    if (next.path.length > 1) {
      // has parent, which is child of id second last in path
      const parent = next.path[next.path.length - 2];

      // assign parent relation
      Object.assign(comment, { parent });

      //@ts-ignore
      if (!acc[parent]) {
        // parent does not exist yet,
        // create it. I think this should
        // not happen ever, but who knows
        //@ts-ignore
        acc[parent] = {
          children: [next.id] || [],
        };
        //@ts-ignore
      } else if (acc[parent] && !acc[parent].children) {
        // parent exists (as should), but it does not
        // contain `children` property, yet
        //@ts-ignore
        acc[parent].children = [next.id];
      } else {
        // parent already has children, add this
        // in the list. Note mutation with push
        //@ts-ignore
        acc[parent].children.push(next.id);
      }
    }
    //@ts-ignore
    acc[next.id] = comment;

    return acc;
  }, {});
};

//@ts-ignore
const getRootComments = state => Object.values(state).filter(({ parent }) => !parent);

const Comment: React.FC<BoxProps & {
  comment: any;
  onAddComment: (text: string, parentId?: number) => void;
  onDeleteComment: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      content: any;
    }>
  >;
}> = ({ comment, onAddComment, onDeleteComment, ...rest }) => {
  const { user } = useUser();

  return (
    <Flex direction="column" {...rest}>
      <Stack isInline spacing={2}>
        <Avatar src={comment.profileImage} />
        <Flex backgroundColor="gray.100" justify="space-between" w="100%" p={4} mb={6} rounded="md">
          <Stack spacing={4}>
            <Link to={`/buddy/@${comment.userId}`}>
              <Text fontWeight="medium">{comment.fullName}</Text>
            </Link>
            <Text>{comment.content}</Text>
          </Stack>
          {!!user && user.username === comment.userId && (
            <Popover>
              <PopoverTrigger>
                <IconButton icon={<FaEllipsisV />} aria-label="Comment actions" />
              </PopoverTrigger>
              <PopoverContent zIndex={4}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  <Button
                    colorScheme="red"
                    onClick={() => onDeleteComment({ isOpen: true, content: { commentId: comment.id } })}
                  >
                    Delete
                  </Button>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          )}
        </Flex>
      </Stack>
    </Flex>
  );
};

const commentValidationSchema = yup.object().shape({
  text: yup
    .string()
    .max(255)
    .required('You have to write something'),
});

const CommentForm: React.FC<{
  parentId?: number;
  placeholder: string;
  onAddComment: (text: string, parentId: number) => Promise<any>;
}> = ({ parentId, placeholder, onAddComment }) => {
  return (
    <Formik
      validationSchema={commentValidationSchema}
      initialValues={{
        text: '',
        parentId: parentId,
      }}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        await onAddComment(values.text, values.parentId);
        resetForm();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Flex align="flex-start">
            <Field name="text">
              {({ field, form }: any) => (
                <FormControl isInvalid={form.errors.text && form.touched.text} w="100%">
                  <Input {...field} id="text" size="lg" placeholder={placeholder} />
                  <FormErrorMessage>{form.errors.text}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <IconButton
              aria-label="Send comment"
              colorScheme="teal"
              type="submit"
              isLoading={isSubmitting}
              ml={8}
              icon={<FiSend />}
            />
          </Flex>
        </form>
      )}
    </Formik>
  );
};

export const Comments: React.FC<Props> = ({ comments, onAddComment, onDeleteComment }) => {
  const rootComments = getRootComments(renderComments(comments));
  const renderedComments = renderComments(comments);

  return (
    <Flex direction="column">
      {rootComments.map((rootComment: any) => (
        <Box key={rootComment.id}>
          <Comment comment={rootComment} onAddComment={onAddComment} onDeleteComment={onDeleteComment} />
          <Flex direction="column">
            <Box ml="3.5rem">
              {rootComment.children &&
                rootComment.children.map((child: any) => (
                  <Comment
                    key={child}
                    onAddComment={onAddComment}
                    onDeleteComment={onDeleteComment}
                    // @ts-ignore
                    comment={renderedComments[child] as any}
                  />
                ))}

              <CommentForm placeholder="Reply" parentId={rootComment.id} onAddComment={onAddComment} />
            </Box>
          </Flex>
          <Divider my={6} />
        </Box>
      ))}
      <Flex>
        <CommentForm placeholder="Add a comment" onAddComment={onAddComment} />
      </Flex>
    </Flex>
  );
};
