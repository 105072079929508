import React, { useRef } from 'react';
import { Formik, Field, FieldArray } from 'formik';
import { Flex, Button, Box, Stack, Tag, Avatar, TagLabel, TagCloseButton, useDisclosure } from '@chakra-ui/react';
import * as Yup from 'yup';
import { isSameDay, startOfToday, endOfDay, addMinutes } from 'date-fns';

import { DateInput, DropzoneInput, MultiSelectInput, BuddyPopover } from '../Molecules';
import { LocationFieldWithMap } from './LocationFieldWithMap';
import { FormCheckbox, FormInput, FormTextarea } from '../shared/components';

const validationSchema = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string().required(),
  longitude: Yup.string().test(
    'longitude',
    '* Obligatoriskt',
    //@ts-ignore
    function(value) {
      if (!!this.parent.location) {
        return true;
      }

      if (value) return value !== 0;
      return true;
    }
  ),
  tmp: Yup.string().test(
    'tmp',
    '* Obligatoriskt',
    //@ts-ignore
    function(value) {
      const toUpdate = !!this.parent.location;
      if (toUpdate) {
        return true;
      }

      if (!this.parent.tmpIsOnline && (!this.parent.longitude || !this.parent.latitude)) {
        return false;
      }

      return true;
    }
  ),
  onlineLink: Yup.string().when('tmpIsOnline', {
    is: tmpIsOnline => tmpIsOnline,
    then: Yup.string().required(),
  }),
  startingDate: Yup.string().required(),
  startingTime: Yup.string().required(),
  endingTime: Yup.string().required(),
});

export interface MeetingFormValues {
  title: string;
  description: string;
  latitude: number;
  longitude: number;
  tags: string[];
  picture: any | null;
  pictureUrl: string | null;
  attendees: any[] | null;
  startingDate: Date;
  startingTime: Date;
  endingTime: Date;
  tmp: string;
  isPublic: boolean;
  onlineLink: string;
  tmpIsOnline: boolean;
}

type Props = {
  upsertMeeting: (values: any) => Promise<any>;
  initialValues?: any;
  toUpdate?: boolean;
};

export const MeetingForm: React.FC<Props> = ({ upsertMeeting, initialValues, toUpdate = false }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const buddyRef = useRef(null);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={async (values, { setSubmitting }) => {
        await upsertMeeting(values);
        setSubmitting(false);
      }}
    >
      {({ values, handleSubmit, isSubmitting, isValid, setFieldValue, errors }) => {
        const tagOptions = values.tags.map((tag: any) => ({
          label: tag,
          value: tag,
        }));

        return (
          <Flex>
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <FormInput isRequired name="title" formLabel="Title" />
              <Field formLabel="Featured photo" name="picture" as={DropzoneInput} />
              <FormTextarea
                isRequired
                name="description"
                formLabel="Describe your coworking-session"
                helperText="Let your buddies know what to expect, what you will be working with, what they need to bring, and how to find the group."
              />
              <Flex direction="column">
                <FormCheckbox name="tmpIsOnline" formLabel="Make this an online session" />
                {values.tmpIsOnline ? (
                  <FormInput
                    isRequired
                    name="onlineLink"
                    formLabel="Link to online session"
                    placeholder="https://zoom.us"
                  />
                ) : (
                  <Field formLabel="Where will you be working?" name="tmp" isMandatory as={LocationFieldWithMap} />
                )}
                <Field
                  formLabel="Date"
                  name="startingDate"
                  type="text"
                  minDate={new Date()}
                  comp={DateInput}
                  as={FormInput}
                />
              </Flex>
              <Field
                formLabel="Starting Time"
                name="startingTime"
                type="text"
                comp={DateInput}
                as={FormInput}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                minTime={isSameDay(values.startingDate, new Date()) ? new Date() : startOfToday()}
                maxTime={endOfDay(new Date())}
              />
              <Field
                formLabel="Ending Time"
                name="endingTime"
                type="text"
                comp={DateInput}
                as={FormInput}
                showTimeSelect
                showTimeSelectOnly
                minTime={addMinutes(values.startingTime, 30)}
                maxTime={endOfDay(new Date())}
                dateFormat="HH:mm"
                timeFormat="HH:mm"
              />
              <Field
                name="tags"
                formLabel="Meeting tags"
                placeholder="Meetings tags"
                isMulti
                options={tagOptions}
                as={MultiSelectInput}
                formatCreateLabel={(userInput: string) => `Add tag "${userInput}"`}
                // noOptionsMessage={() => 'Inga alternativ'}
              />
              <FormCheckbox name="isPublic" formLabel="Public meeting" />
              {!values.isPublic && (
                <FieldArray
                  name="attendees"
                  render={arrayHelpers => (
                    <>
                      <BuddyPopover
                        isOpen={isOpen}
                        initialFocusRef={buddyRef}
                        onOpen={onOpen}
                        onClose={onClose}
                        triggerLabel="Invite buddies"
                        push={arrayHelpers.push}
                        addedUsernames={values.attendees}
                      />

                      <Stack isInline spacing={8} align="center" mt={3}>
                        {values.attendees.map((m: any, i: number) => (
                          <Tag rounded="full" key={m}>
                            <Avatar src={m.profileImage} size="md" name={m.username} ml={-3} mr={2} />
                            <TagLabel>{m.username}</TagLabel>
                            <TagCloseButton onClick={() => arrayHelpers.remove(i)} />
                          </Tag>
                        ))}
                      </Stack>
                    </>
                  )}
                />
              )}
              <Box mt={4}>
                <Button colorScheme="teal" type="submit" isDisabled={isSubmitting || !isValid} isLoading={isSubmitting}>
                  {toUpdate ? 'Update session' : 'Create session'}
                </Button>
              </Box>
            </form>
          </Flex>
        );
      }}
    </Formik>
  );
};
