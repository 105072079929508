import React from 'react';
import { ErrorMessage, Field, FieldAttributes, FieldProps } from 'formik';
import {
  FormControl,
  FormErrorMessage,
  FormErrorMessageProps,
  FormHelperText,
  FormLabel,
  FormLabelProps,
} from '@chakra-ui/form-control';
import { Input, InputProps } from '@chakra-ui/input';

interface IFormInput {
  name: string;
  formLabel?: string;
  helperText?: string;
  isRequired?: boolean;
  formLabelProps?: FormLabelProps;
  formErrorMessageProps?: FormErrorMessageProps;
}

type FormInputProps = FieldAttributes<IFormInput & InputProps>;

export const FormInput: React.FC<FormInputProps> = ({
  name,
  formLabel,
  helperText,
  isRequired = false,
  formLabelProps,
  formErrorMessageProps,
  ...rest
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl id={name} isRequired={isRequired} isInvalid={!!form.touched[name] && !!form.errors[name]} mb={4}>
          {formLabel && (
            <FormLabel htmlFor={name} {...formLabelProps}>
              {formLabel}
            </FormLabel>
          )}
          <Input {...(field as any)} {...rest} id={name} />
          <ErrorMessage name={name}>
            {errorMessage => <FormErrorMessage {...formErrorMessageProps}>{errorMessage}</FormErrorMessage>}
          </ErrorMessage>
          {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
};
