import React, { useState } from 'react';
import {
  Heading,
  Box,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  Flex,
  Text,
  useColorMode,
  Link,
  Icon,
} from '@chakra-ui/react';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { FiClock, FiMap } from 'react-icons/fi';
import { useQuery } from '@apollo/react-hooks';

import { Container } from '../../Atoms';
import { FindMmMeetingsQuery, FindMmMeetingsQueryVariables, MeetingMember } from '../../generated/graphql';
import { FIND_MM_MEETINGS } from '../../graphql/queries';
import { formatMeetingTime } from '../../utils';
import { viewMeetingColors } from '../ViewMeeting';
import { LinkButton, MapWithAMarker, Spinner } from '../../Molecules';
import { useViewMeeting } from '../../hooks';
import { OnlineLinkPopOverInfo, MeetingMemberCard } from '../../components';

const locales = {
  'en-US': require('date-fns/locale/en-US'),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

type MeetingEvents = {
  title: string;
  allDay: boolean;
  start: Date;
  end: Date;
  resource: {
    meetingId: string;
  };
};

export const UpcomingMeetings: React.FC<{}> = () => {
  const [drawerState, setDrawerState] = useState({
    isOpen: false,
    meetingId: null,
  });
  const btnRef = React.useRef();

  const { data, loading } = useQuery<FindMmMeetingsQuery, FindMmMeetingsQueryVariables>(FIND_MM_MEETINGS, {
    variables: {
      limit: 10,
      offset: 0,
      hasAccepted: true,
    },
  });

  if (loading) return <Spinner />;

  const events: MeetingEvents[] = data.findMMMeetings.map(meeting => ({
    title: meeting.title,
    allDay: false,
    start: new Date(format(meeting.startingDate, 'yyyy-MM-dd kk:mm')),
    end: new Date(format(meeting.endingDate, 'yyyy-MM-dd kk:mm')),
    resource: { meetingId: meeting.id },
  }));

  const openMeetingDrawer = (meetingId: string) => {
    setDrawerState({
      isOpen: true,
      meetingId,
    });
  };

  const closeMeetingDrawer = () => setDrawerState({ isOpen: false, meetingId: null });

  return (
    <Container>
      <Heading textAlign="center" mb={6}>
        Upcoming meetings
      </Heading>
      <Box>
        {drawerState.isOpen && (
          <MeetingDrawer
            isOpen={drawerState.isOpen}
            onClose={closeMeetingDrawer}
            btnRef={btnRef}
            meetingId={drawerState.meetingId}
          />
        )}
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          defaultDate={new Date()}
          onSelectEvent={event => openMeetingDrawer(event.resource.meetingId)}
          //@ts-ignore
          style={{ height: 500 }}
        />
      </Box>
    </Container>
  );
};

//@ts-ignore
const MeetingDrawer = ({ isOpen, onClose, btnRef, meetingId }) => {
  const { colorMode } = useColorMode();

  const { data, loading } = useViewMeeting(Number(meetingId), false);

  if (loading) {
    return null;
  }

  const { viewMeeting: meeting } = data;
  const isOnline = !!meeting.onlineLink;
  const attendingMembers = meeting.attendees.filter((attendee: any) => !!attendee.hasAccepted) as MeetingMember[];

  return (
    <Drawer size="lg" isOpen={isOpen} placement="left" onClose={onClose} finalFocusRef={btnRef}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">{meeting.title}</DrawerHeader>
        <DrawerBody pt={4}>
          {isOnline ? (
            <Stack mb={12}>
              <Flex>
                <Box as={FiClock} size="24px" color={viewMeetingColors.icon[colorMode]} />
                <Text pb={4} pl={4} color={viewMeetingColors.text[colorMode]}>
                  {formatMeetingTime({ startingDate: meeting.startingDate, endingDate: meeting.endingDate })}
                </Text>
              </Flex>
              <Flex>
                <Box as={FiMap} size="24px" color={viewMeetingColors.icon[colorMode]} />
                <OnlineLinkPopOverInfo>
                  <Text as="span" textDecor="underline" mr={1} pl={4}>
                    Online session:
                  </Text>
                </OnlineLinkPopOverInfo>
                <Text pb={4} color={viewMeetingColors.text[colorMode]}>
                  <Link href={meeting.onlineLink} isExternal color="blue.500">
                    {meeting.onlineLink} <Icon name="external-link" mx="2px" />
                  </Link>
                </Text>
              </Flex>
            </Stack>
          ) : (
            <Stack mb={12}>
              <Flex>
                <Box as={FiClock} size="24px" color={viewMeetingColors.icon[colorMode]} />
                <Text pb={4} pl={4} color={viewMeetingColors.text[colorMode]}>
                  {formatMeetingTime({ startingDate: meeting.startingDate, endingDate: meeting.endingDate })}
                </Text>
              </Flex>
              <Flex>
                <Box as={FiMap} size="24px" color={viewMeetingColors.icon[colorMode]} />
                <Text pb={4} pl={4} color={viewMeetingColors.text[colorMode]}>
                  {meeting.location.addressLine}, {meeting.location.country}, {meeting.location.city}
                </Text>
              </Flex>
              <MapWithAMarker
                containerElement={<Box height="250px" borderRadius="1rem" />}
                mapElement={<Box height="100%" borderRadius="lg" />}
                defaultCenter={{ lat: meeting.location.latitude, lng: meeting.location.longitude }}
                lat={meeting.location.latitude}
                lng={meeting.location.longitude}
              />
            </Stack>
          )}
          <Stack mb={12}>
            <Heading as="h4" size="sm" color={viewMeetingColors.heading[colorMode]}>
              About
            </Heading>
            <Text color={viewMeetingColors.text[colorMode]}>{meeting.description}</Text>
          </Stack>
          <Flex mb={4} align="center" justify="space-between">
            <Heading as="h4" size="sm" mr={2} color={viewMeetingColors.heading[colorMode]}>
              Buddies ({meeting.attendees.length})
            </Heading>
          </Flex>
          <Flex flexWrap="wrap">
            {attendingMembers.map((meetingMember, i) => (
              <MeetingMemberCard key={i} meetingMember={meetingMember} />
            ))}
          </Flex>
        </DrawerBody>
        <DrawerFooter>
          <LinkButton colorScheme="blue" to={`/meeting/${meeting.id}`}>
            View meeting
          </LinkButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
