import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { ApolloProvider } from '@apollo/react-hooks';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga';

import * as serviceWorker from './serviceWorker';
import { client } from './apollo';
import { LanguageProvider, ModalProvider } from './Providers';
import { App } from './App';
import { DrawerMenuProvider } from './Providers/Drawer';

Sentry.init({
  dsn: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SENTRY_DSN : null,
  environment: process.env.NODE_ENV,
});

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKING_ID);
}

ReactDOM.render(
  <LanguageProvider>
    <ChakraProvider resetCSS>
      <ApolloProvider client={client as any}>
        <DrawerMenuProvider>
          <ModalProvider>
            <App />
          </ModalProvider>
        </DrawerMenuProvider>
      </ApolloProvider>
    </ChakraProvider>
  </LanguageProvider>,
  document.getElementById('root')
);
serviceWorker.unregister();
