import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { RouteComponentProps, Link, Redirect } from 'react-router-dom';
import {
  Box,
  Image,
  Text,
  Flex,
  Heading,
  useToast,
  Stack,
  Divider,
  useColorMode,
  Button,
  Tag,
  Link as CLink,
  Icon,
} from '@chakra-ui/react';
import { FiClock, FiMap, FiShare } from 'react-icons/fi';
import Helmet from 'react-helmet';

import { VIEW_MEETING } from '../../graphql/queries';
import {
  ADD_TO_MEETING_MUTATION,
  REMOVE_FROM_MEETING,
  CREATE_MEETING_COMMENT,
  UPSERT_MEETING_INVITATION,
} from '../../graphql/mutations';
import {
  AddToMeetingMutation,
  AddToMeetingMutationVariables,
  RemoveFromMeetingMutationVariables,
  RemoveFromMeetingMutation,
  CreateMeetingCommentMutation,
  CreateMeetingCommentMutationVariables,
  UpsertMeetingInvitationMutation,
  UpsertMeetingInvitationMutationVariables,
  MeetingMember,
} from '../../generated/graphql';
import { useUser } from '../../contexts';
import { Container } from '../../Atoms';
import { MapWithAMarker } from '../../Molecules/MapWithMarker';
import { Spinner, LinkButton } from '../../Molecules';
import { useViewMeeting } from '../../hooks';
import { Comments } from '../../Organisms/Comments';
import { DeleteCommentAlertDialog, StickyMeetingFooter } from './components';
import { isOwnerOfMeeting, formatMeetingTime } from '../../utils';
import { useModal } from '../../Providers';
import { modalNames } from '../../constants';
import { OnlineLinkPopOverInfo, MeetingMemberCard } from '../../components';

export const viewMeetingColors = {
  heading: { light: 'gray.800', dark: 'gray.50' },
  text: { light: 'gray.700', dark: 'gray.50' },
  border: { light: 'gray.200', dark: 'whiteAlpha.300' },
  card: { light: 'gray.100', dark: 'gray.700' },
  icon: { light: 'gray.600', dark: 'gray.500' },
};

type Props = RouteComponentProps<{ meetingId: string }>;

export const ViewMeeting: React.FC<Props> = ({ match }) => {
  const { user } = useUser();
  const toast = useToast();
  const { colorMode } = useColorMode();
  const { openModal } = useModal();

  const [deleteAlertDialog, setDeleteAlertDialog] = useState({ isOpen: false, content: null });

  const cancelRef = useRef();

  const { data, loading, error } = useViewMeeting(Number(match.params.meetingId), false);

  const [createMeetingComment] = useMutation<CreateMeetingCommentMutation, CreateMeetingCommentMutationVariables>(
    CREATE_MEETING_COMMENT
  );
  const [assignToMeeting] = useMutation<AddToMeetingMutation, AddToMeetingMutationVariables>(ADD_TO_MEETING_MUTATION);
  const [removeFromMeeting] = useMutation<RemoveFromMeetingMutation, RemoveFromMeetingMutationVariables>(
    REMOVE_FROM_MEETING
  );
  const [upsertMeetingInvitation] = useMutation<
    UpsertMeetingInvitationMutation,
    UpsertMeetingInvitationMutationVariables
  >(UPSERT_MEETING_INVITATION);

  const showToast = (message: string, title: string, status: 'success' | 'warning') =>
    toast({
      title: title,
      description: message,
      status: status,
      duration: 5000,
      isClosable: true,
      position: 'top-right',
    });

  const handleAddToMeeting = async () => {
    const {
      data: {
        addToMeeting: [a],
      },
    } = await assignToMeeting({
      variables: {
        meetingId: match.params.meetingId,
        userId: user.username,
        hasAccepted: true,
      },
      update(cache) {
        cache.readQuery({
          query: VIEW_MEETING,
          variables: { id: Number(match.params.meetingId), toUpdate: false },
        });
      },
    });

    if (a.__typename === 'Error') {
      showToast(a.message, 'Uho', 'warning');
    } else {
      const { viewMeeting: meeting } = data;
      showToast(`You have signed up to ${meeting.title}`, 'Success', 'success');
    }
  };

  const handleRemoveFromMeeting = async () => {
    const {
      data: {
        removeFromMeeting: [r],
      },
    } = await removeFromMeeting({
      variables: {
        meetingId: match.params.meetingId,
        userId: user.username,
      },
      update(cache) {
        cache.readQuery({
          query: VIEW_MEETING,
          variables: { id: Number(match.params.meetingId), toUpdate: false },
        });
      },
    });

    if (r.__typename === 'Error') {
      showToast(r.message, 'Uho', 'warning');
    } else {
      const { viewMeeting: meeting } = data;
      showToast(`You have been removed from ${meeting.title}`, 'Success', 'success');
    }
  };

  const handleUpsertMeetingInvitation = async () => {
    await upsertMeetingInvitation({
      variables: {
        meetingId: match.params.meetingId,
      },
    });
  };

  const handleAssignToMeeting = async (alreadySignedUp: boolean, isInvited: boolean) => {
    if (!user) {
      return openModal(modalNames.SIGN_UP_MODAL);
    }
    if (alreadySignedUp) {
      handleRemoveFromMeeting();
    } else if (isInvited) {
      handleUpsertMeetingInvitation();
    } else {
      handleAddToMeeting();
    }
  };

  if (loading) return <Spinner />;
  if (!!error || data.viewMeeting === null) return <Redirect to="/notFound" />;

  const { viewMeeting: meeting } = data;

  const acceptedAttendees = meeting.attendees.filter(attendee => attendee.hasAccepted);
  const alreadySignedUp = !!user && !!acceptedAttendees.find(attendee => attendee.user.username === user.username);
  const isInvited =
    !!user && !!meeting.attendees.find(attendee => !attendee.hasAccepted && attendee.user.username === user.username);
  const isOwnMeeting =
    !!user && !!acceptedAttendees.find(attendee => attendee.isOwner && attendee.user.username === user.username);
  const isOnline = !!meeting.onlineLink;

  const handleAddComment = async (text: string, parentId?: number) => {
    if (!user) {
      return openModal(modalNames.SIGN_UP_MODAL);
    }
    const res = await createMeetingComment({
      variables: {
        text,
        parentId,
        meetingId: Number(meeting.id),
      },
      update(cache) {
        cache.readQuery({
          query: VIEW_MEETING,
          variables: { id: Number(match.params.meetingId), toUpdate: false },
        });
      },
    });
    return res;
  };

  return (
    <>
      <Helmet>
        <title>{meeting.title} | Coworkingbuddies</title>
      </Helmet>
      <Container mb={6}>
        <Box rounded="lg" boxShadow="lg" p={[4, 8]}>
          <Box>
            {!!meeting.pictureUrl && (
              <Image rounded="md" src={meeting.pictureUrl} w="100%" h="250px" objectFit="cover" />
            )}
            <Flex direction="column" mt={2}>
              <Stack mb={8}>
                <Heading as="h5" size="sm" color="gray.400">
                  Session's name
                </Heading>
                <Flex justify="space-between" align="center">
                  <Heading color={viewMeetingColors.heading[colorMode]}>{meeting.title}</Heading>
                  <Flex>
                    <Button onClick={() => openModal(modalNames.SHARE_MODAL, { meeting })} rightIcon={<FiShare />}>
                      Share
                    </Button>
                    {!!user && isOwnerOfMeeting(meeting.attendees, user) && (
                      <LinkButton to={`/meeting/${meeting.id}/edit`} ml={4}>
                        Edit
                      </LinkButton>
                    )}
                  </Flex>
                </Flex>
              </Stack>
              {isOnline ? (
                <Stack mb={12}>
                  <Flex>
                    <Box as={FiClock} size="24px" color={viewMeetingColors.icon[colorMode]} />
                    <Text pb={4} pl={4} color={viewMeetingColors.text[colorMode]}>
                      {formatMeetingTime({ startingDate: meeting.startingDate, endingDate: meeting.endingDate })}
                    </Text>
                  </Flex>
                  <Flex>
                    <Box as={FiMap} size="24px" color={viewMeetingColors.icon[colorMode]} />
                    <OnlineLinkPopOverInfo>
                      <Text as="span" textDecor="underline" mr={1} pl={4}>
                        Online session:
                      </Text>
                    </OnlineLinkPopOverInfo>
                    <Text pb={4} color={viewMeetingColors.text[colorMode]}>
                      {alreadySignedUp || isInvited ? (
                        <CLink href={meeting.onlineLink} isExternal color="blue.500">
                          {meeting.onlineLink} <Icon name="external-link" mx="2px" />
                        </CLink>
                      ) : (
                        'You have to attend to see the link'
                      )}
                    </Text>
                  </Flex>
                </Stack>
              ) : (
                <Stack mb={12}>
                  <Flex>
                    <Box as={FiClock} size="24px" color={viewMeetingColors.icon[colorMode]} />
                    <Text pb={4} pl={4} color={viewMeetingColors.text[colorMode]}>
                      {formatMeetingTime({ startingDate: meeting.startingDate, endingDate: meeting.endingDate })}
                    </Text>
                  </Flex>
                  <Flex>
                    <Box as={FiMap} size="24px" color={viewMeetingColors.icon[colorMode]} />
                    <Text pb={4} pl={4} color={viewMeetingColors.text[colorMode]}>
                      {meeting.location.addressLine}, {meeting.location.country}, {meeting.location.city}
                    </Text>
                  </Flex>
                  <MapWithAMarker
                    containerElement={<Box height="250px" borderRadius="1rem" />}
                    mapElement={<Box height="100%" borderRadius="lg" />}
                    defaultCenter={{ lat: meeting.location.latitude, lng: meeting.location.longitude }}
                    lat={meeting.location.latitude}
                    lng={meeting.location.longitude}
                  />
                </Stack>
              )}
            </Flex>
            <Stack mb={12}>
              <Heading as="h3" size="md" color={viewMeetingColors.heading[colorMode]}>
                About
              </Heading>
              <Text color={viewMeetingColors.text[colorMode]}>{meeting.description}</Text>
            </Stack>
            <Flex direction="column">
              <Flex mb={4} align="center" justify="space-between">
                <Heading as="h3" size="md" mr={2} color={viewMeetingColors.heading[colorMode]}>
                  Buddies ({meeting.attendees.length})
                </Heading>
                {!!user && isOwnerOfMeeting(meeting.attendees, user) && (
                  <Button onClick={() => openModal(modalNames.MANAGE_BUDDIES_MODAL, { meeting })}>Manage</Button>
                )}
              </Flex>
              <Flex flexWrap="wrap">
                {meeting.attendees.map((meetingMember, i) => (
                  <MeetingMemberCard key={i} meetingMember={meetingMember as MeetingMember} />
                ))}
              </Flex>
            </Flex>
            <Stack>
              <Heading as="h3" size="md" mb={4} color={viewMeetingColors.heading[colorMode]}>
                Comments
              </Heading>
              <Flex direction="column">
                <Comments
                  comments={meeting.comments}
                  onAddComment={handleAddComment}
                  onDeleteComment={setDeleteAlertDialog}
                />
              </Flex>
            </Stack>
          </Box>
          <Divider marginY={6} />
          <Flex direction="column">
            <Heading as="h3" size="md" mb={4} color={viewMeetingColors.heading[colorMode]}>
              Tags
            </Heading>
            <Flex flexWrap="wrap">
              {meeting.tags.map((tag, i) => (
                <Link
                  key={i}
                  to={{
                    pathname: '/meetings',
                    search: `?tags=${tag.name}`,
                  }}
                >
                  <Tag rounded="full" colorScheme="cyan" mr={4} mb={4}>
                    {tag.name}
                  </Tag>
                </Link>
              ))}
            </Flex>
          </Flex>
        </Box>
        {deleteAlertDialog.isOpen && (
          <DeleteCommentAlertDialog
            onClose={() => setDeleteAlertDialog(curr => ({ ...curr, isOpen: false }))}
            isOpen={deleteAlertDialog.isOpen}
            cancelRef={cancelRef}
            content={{ ...deleteAlertDialog.content, meetingId: meeting.id }}
          />
        )}
      </Container>
      <StickyMeetingFooter
        isOwnMeeting={isOwnMeeting}
        handleAssignToMeeting={handleAssignToMeeting}
        meeting={meeting}
        alreadySignedUp={alreadySignedUp}
        isInvited={isInvited}
      />
    </>
  );
};
