import { gql } from 'apollo-boost';

export const FIND_PROJECTS = gql`
  query FindProjects($limit: Float!, $offset: Float!) {
    findProjects(limit: $limit, offset: $offset) {
      id
      title
      createdAt
      members {
        user {
          username
        }
      }
      managers {
        user {
          username
        }
      }
    }
  }
`;

export const VIEW_PROJECT = gql`
  query ViewProject($projectId: String!) {
    viewProject(projectId: $projectId) {
      title
      description
      members {
        user {
          username
        }
      }
      managers {
        user {
          username
        }
      }
      projectMeetings {
        meeting {
          id
          title
          attendees {
            user {
              username
            }
          }
        }
      }
    }
  }
`;
