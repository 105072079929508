import * as React from 'react';
import { DrawerContext } from '../../contexts';
import { IDrawerContext } from '../../types';

type Props = { children: React.ReactNode };

export const DrawerMenuProvider = ({ children }: Props) => {
  const [isDrawerOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = React.useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  const contextValue = React.useMemo<IDrawerContext>(() => ({ isDrawerOpen, toggleDrawer }), [isDrawerOpen]);

  return <DrawerContext.Provider value={contextValue}>{children}</DrawerContext.Provider>;
};
