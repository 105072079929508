import React from 'react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/react-hooks';

import { DeleteMeetingCommentMutation, DeleteMeetingCommentMutationVariables } from '../../../generated/graphql';
import { DELETE_MEETING_COMMENT } from '../../../graphql/mutations';
import { VIEW_MEETING } from '../../../graphql/queries';

type Props = {
  isOpen: boolean;
  onClose: any;
  cancelRef: React.MutableRefObject<undefined>;
  content: {
    meetingId: string;
    commentId: number;
  };
};

export const DeleteCommentAlertDialog: React.FC<Props> = ({ isOpen, onClose, cancelRef, content }) => {
  const [deleteMeetingCommentMutation, { loading, data }] = useMutation<
    DeleteMeetingCommentMutation,
    DeleteMeetingCommentMutationVariables
  >(DELETE_MEETING_COMMENT, {
    variables: {
      meetingId: Number(content.meetingId),
      commentId: content.commentId,
    },
    update(cache) {
      cache.readQuery({
        query: VIEW_MEETING,
        variables: { id: Number(content.meetingId), toUpdate: false },
      });
    },
  });

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Delete Comment
        </AlertDialogHeader>

        <AlertDialogBody>
          Are you sure? All of it's replies will be deleted. You can't undo this action afterwards.
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            I'll pass
          </Button>
          <Button
            colorScheme="red"
            isLoading={loading}
            onClick={async () => {
              await deleteMeetingCommentMutation();
              onClose();
            }}
            ml={3}
          >
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
