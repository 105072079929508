import React, { useEffect } from 'react';
import { Flex, Heading, Divider, useToast } from '@chakra-ui/react';
import { RouteComponentProps, Redirect } from 'react-router';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { Container } from '../../Atoms';
import { MeetingForm, MeetingFormValues } from '../../Organisms';
import { Spinner } from '../../Molecules';
import {
  UpdateMeetingMutation,
  UpdateMeetingMutationVariables,
  ViewMeetingToUpdateQuery,
  ViewMeetingToUpdateQueryVariables,
  SignS3Mutation,
  SignS3MutationVariables,
} from '../../generated/graphql';
import { UPDATE_MEETING, SIGN_S3 } from '../../graphql/mutations';
import { VIEW_MEETING_TO_UPDATE } from '../../graphql/queries';
import { toFormatedDate, toFormatedTime, formatFileName, uploadToS3 } from '../../utils';
import { useUser } from '../../contexts';

type Props = RouteComponentProps<{ meetingId: string }>;

export const UpdateMeeting: React.FC<Props> = ({ match, history }) => {
  const toast = useToast();
  const { user } = useUser();

  const { data, loading } = useQuery<ViewMeetingToUpdateQuery, ViewMeetingToUpdateQueryVariables>(
    VIEW_MEETING_TO_UPDATE,
    {
      variables: {
        id: Number(match.params.meetingId),
        //@ts-ignore
        toUpdate: true,
      },
    }
  );
  const [signS3] = useMutation<SignS3Mutation, SignS3MutationVariables>(SIGN_S3);
  const [updateMeetingMutation, { data: updateMeetingData, called }] = useMutation<
    UpdateMeetingMutation,
    UpdateMeetingMutationVariables
  >(UPDATE_MEETING);

  useEffect(() => {
    let id: any;
    if (called && !loading) {
      id = setTimeout(() => {
        history.push(`/meeting/${data.viewMeeting.id}`);
      }, 2250);
      toast({
        title: 'Session updated',
        status: 'success',
        position: 'top-right',
        duration: 2000,
      });
    }
    return () => clearTimeout(id);
  }, [updateMeetingData]);

  const meetingLocation = (values: MeetingFormValues & { location: any }) => {
    if (values.tmpIsOnline) {
      return {
        onlineLink: values.onlineLink,
      };
    } else {
      return {
        latitude: values.latitude || data.viewMeeting.location.latitude,
        longitude: values.longitude || data.viewMeeting.location.longitude,
      };
    }
  };

  const handleUpdateMeeting = async (values: MeetingFormValues & { location: any }) => {
    const {
      location,
      startingTime: st,
      endingTime: et,
      startingDate: sd,
      picture,
      pictureUrl,
      attendees,
      tmp,
      tmpIsOnline,
      ...rest
    } = values;
    let outerUrl = pictureUrl || '';

    if (picture) {
      const res = await signS3({
        variables: {
          fileName: formatFileName(picture.name),
          fileType: picture.type,
        },
      });

      const { signedRequest, url } = res.data.signS3;
      outerUrl = url;

      await uploadToS3(picture, signedRequest);
    }

    const startingDate = new Date(`${toFormatedDate(sd)} ${toFormatedTime(st)}`);
    const endingDate = new Date(`${toFormatedDate(sd)} ${toFormatedTime(et)}`);

    const vals = {
      ...rest,
      pictureUrl: outerUrl,
      startingDate,
      endingDate,
      attendees: attendees.map(a => a.user.username).concat(user.username), // will grab this id when creating a meeting to put the user as owner
      ...meetingLocation(values),
    };

    return await updateMeetingMutation({ variables: { input: vals, meetingId: match.params.meetingId } });
  };

  if (loading) return <Spinner />;

  if (data.viewMeeting === null) {
    return <Redirect to="/meetings" />;
  } else {
    const { __typename, id, endingDate, tags, ...meetingToUpdate } = data.viewMeeting;

    const initialValues = {
      ...meetingToUpdate,
      startingTime: meetingToUpdate.startingDate,
      endingTime: endingDate,
      tmp: meetingToUpdate.location ? meetingToUpdate.location.addressLine : '',
      //@ts-ignore
      tmpIsOnline: !!meetingToUpdate.onlineLink,
      tags: tags.map(tag => tag.name),
    };

    return (
      <Container>
        <Flex m={4} justifyContent="center" flexDirection="column">
          <Heading textAlign="center">Update Coworking Session</Heading>
          <Divider my={4} />
          <MeetingForm upsertMeeting={handleUpdateMeeting} initialValues={initialValues} toUpdate />
        </Flex>
      </Container>
    );
  }
};
