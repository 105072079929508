import * as React from 'react';
import { IUserContext, User } from '../../types';
import { UserContext } from '../../contexts';

export default class UserProvider extends React.Component {
  state: IUserContext = {
    user: null,
    setUser: (user: User) => this.setState({ user })
  };

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}