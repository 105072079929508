import * as React from 'react';
import { Flex, Heading, Text, Stack } from '@chakra-ui/react';

import { Container } from '../../Atoms';
import { LinkButton } from '../../Molecules';

export const BecomeABuddy: React.FC<{}> = () => {
  return (
    <Container height="100%">
      <Flex direction="column" align="center" height="100%" justify="center">
        <Stack spacing={2} mb={4} align="center">
          <Heading>Become a buddy</Heading>
          <Text textAlign="center">
            We let people with laptops connect in real life to create new projects and meetings.
          </Text>
        </Stack>
        <LinkButton to="/register" size="lg" colorScheme="teal">
          Join now!
        </LinkButton>
      </Flex>
    </Container>
  );
};
