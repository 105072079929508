import { ApolloClient, InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-boost';
import { createUploadLink } from 'apollo-upload-client';
import { split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { createHttpLink } from 'apollo-link-http';

import introspectionResults from './introspection-result';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionResults,
});

const uploadLink = createUploadLink({
  uri: 'http://localhost:4000/graphql',
  credentials: 'include',
});

// const wsLink = new WebSocketLink({
//   uri: `ws://localhost:4000`,
//   options: {
//     reconnect: true,
//   },
// });

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query) as any;

    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  // wsLink,
  uploadLink
);

export const client = new ApolloClient({
  link: createHttpLink({ uri: process.env.REACT_APP_SERVER_URL, credentials: 'include' }),
  cache: new InMemoryCache({ fragmentMatcher }),
});
