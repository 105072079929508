import { ButtonGroup, ButtonGroupProps, IconButton } from '@chakra-ui/react';
import * as React from 'react';
import { FaInstagram, FaTwitter } from 'react-icons/fa';

export const SocialMediaLinks = (props: ButtonGroupProps) => (
  <ButtonGroup variant="ghost" color="gray.600" {...props}>
    <IconButton
      as="a"
      target="_blank"
      href="https://instagram.com/coworkingbuddies"
      aria-label="Instagram"
      icon={<FaInstagram fontSize="20px" />}
    />
    <IconButton
      as="a"
      target="_blank"
      href="https://twitter.com/coworkingbuddies"
      aria-label="Twitter"
      icon={<FaTwitter fontSize="20px" />}
    />
  </ButtonGroup>
);
