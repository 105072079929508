import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { RouteComponentProps, Switch, withRouter, Route } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';
import Helmet from 'react-helmet';

import routes from './routes';
import { meQuery } from '../../graphql/queries';
import { parseQueryString, scrollToElement } from '../../utils/Helpers';
import { LanguageContext } from '../../contexts/Lang';
import { DrawerContext, UserContext } from '../../contexts';
import { NotFound } from '../../Routes/Pages';
import { initGA, canTrackPath } from '../../utils';
import { PageLayout, Footer, Navbar } from '../../shared/components';

type Props = RouteComponentProps;

const RoutingProvider = (props: Props) => {
  const { setLang } = React.useContext(LanguageContext);
  const { user, setUser } = React.useContext(UserContext);
  const { isDrawerOpen, toggleDrawer } = React.useContext(DrawerContext);
  const { location } = props;
  const [isLoading, setLoading] = React.useState<boolean>(true);

  const { data, networkStatus, loading } = useQuery<any>(meQuery, {
    fetchPolicy: 'network-only',
  });

  React.useEffect(() => {
    if (networkStatus === 7) {
      setUser(data!.me);
      setLoading(false);
    }
  }, [data, loading]);

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production' && canTrackPath(location.pathname)) {
      initGA(props.history);
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (isDrawerOpen) {
      toggleDrawer();
    }

    if (location.search) {
      const query = parseQueryString(location.search);
      query.lang && setLang(query.lang);
    }

    if (location.hash) {
      scrollToElement(location.hash);
    }
  }, [location.search, location.hash, location.pathname]);

  return (
    <Switch>
      {routes.map(route => (
        <Route
          key={route.path}
          path={route.path}
          exact={true}
          render={props => {
            let Component = route.component;
            const Footer = route.footer;
            if (route.unauthed && !user) {
              Component = route.unauthed || null;
            }
            return (
              <>
                {route.title && (
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>{route.title}</title>
                  </Helmet>
                )}
                <PageLayout
                  header={route.nav ? <Navbar user={user} /> : null}
                  main={isLoading ? <Spinner /> : <Component {...props} />}
                  footer={Footer && <Footer />}
                />
              </>
            );
          }}
        />
      ))}
      <Route render={() => <PageLayout header={<Navbar user={user} />} main={<NotFound />} footer={<Footer />} />} />
    </Switch>
  );
};

export default withRouter(RoutingProvider);
