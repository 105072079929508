import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Formik, Field } from 'formik';
import { Flex, Heading, Button, useToast } from '@chakra-ui/react';
import * as yup from 'yup';

import { UPDATE_USER, SIGN_S3 } from '../../graphql/mutations';
import { Container } from '../../Atoms';
import { useUser } from '../../contexts';
import {
  UpdateUserMutation,
  MutationUpdateUserArgs,
  SignS3Mutation,
  SignS3MutationVariables,
} from '../../generated/graphql';
import { meQuery } from '../../graphql/queries';
import { DropzoneInput } from '../../Molecules';
import { formatFileName, uploadToS3 } from '../../utils';
import { FormInput } from '../../shared/components';

const validationSchema = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  bio: yup.string(),
  location: yup.string(),
  profession: yup.string(),
});

export interface UserFormValues {
  firstName?: string;
  lastName?: string;
  bio?: string;
  location?: string;
  profession?: string;
  picture?: any | null;
  profileImage?: string | null;
}

export const UpdateUser: React.FC<{}> = () => {
  const toast = useToast();
  const { user } = useUser();

  const [updateUser] = useMutation<UpdateUserMutation, MutationUpdateUserArgs>(UPDATE_USER);
  const [signS3] = useMutation<SignS3Mutation, SignS3MutationVariables>(SIGN_S3);

  const handleUpdateUser = async (values: UserFormValues) => {
    const { picture, ...rest } = values;

    let outerUrl = values.profileImage || '';

    if (picture) {
      const res = await signS3({
        variables: {
          fileName: formatFileName(picture.name),
          fileType: picture.type,
        },
      });

      const { signedRequest, url } = res.data.signS3;
      outerUrl = url;

      await uploadToS3(picture, signedRequest);
    }

    await updateUser({
      variables: {
        input: {
          ...rest,
          profileImage: outerUrl,
        },
      },
      update(cache, { data: { updateUser } }) {
        const [updatedUserRes] = updateUser;
        cache.readQuery({
          query: meQuery,
        });
        if (updatedUserRes.__typename !== 'Error') {
          cache.writeQuery({
            query: meQuery,
            data: {
              me: updatedUserRes,
            },
          });
        }
      },
    });
    toast({
      duration: 5000,
      isClosable: true,
      position: 'top-right',
      title: 'Your profile has been updated!',
      status: 'success',
    });
  };

  return (
    <>
      <Container py={4}>
        <Flex direction="column">
          <Heading mb={4}>Settings for buddy @{user.username}</Heading>
          <Formik
            initialValues={{
              pictureUrl: user.profileImage,
              firstName: user.firstName || '',
              lastName: user.lastName || '',
              bio: user.bio || '',
              location: user.location || '',
              profession: user.profession || '',
            }}
            validationSchema={validationSchema}
            onSubmit={({ pictureUrl, ...values }) => handleUpdateUser({ profileImage: pictureUrl, ...values })}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <Field name="picture" formLabel="Profile picture" as={DropzoneInput} />
                <FormInput isRequired name="firstName" placeholder="First name" formLabel="First name" />
                <FormInput isRequired name="lastName" placeholder="Last name" formLabel="Last name" />
                <FormInput isRequired name="bio" placeholder="Bio" formLabel="Bio" />
                <FormInput isRequired name="location" placeholder="Location" formLabel="Location" />
                <FormInput isRequired name="profession" placeholder="Profession" formLabel="Profession" />
                <Button type="submit" colorScheme="teal" size="lg" isDisabled={isSubmitting} isLoading={isSubmitting}>
                  Update account
                </Button>
              </form>
            )}
          </Formik>
        </Flex>
      </Container>
    </>
  );
};
