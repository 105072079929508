import React, { useState, useEffect, useRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  Heading,
  Box,
  List,
  ListItem,
  Stack,
  Text,
  AvatarGroup,
  Avatar,
  Button,
  Icon,
  useDisclosure,
  Flex,
  FormControl,
  Input,
  FormLabel,
  FormErrorMessage,
  Divider,
  Image,
  Tag,
} from '@chakra-ui/react';
import { useLazyQuery } from '@apollo/react-hooks';
import { SEARCH_MEETINGS } from '../../graphql/queries';
import { Spinner, DateInput, MultiSelectInput } from '../../Molecules';
import { MdFilterList } from 'react-icons/md';
import queryString from 'query-string';

import { SearchMeetingsQuery, SearchMeetingsQueryVariables, SearchMeetingInput } from '../../generated/graphql';
import { Formik, Field, Form } from 'formik';
import { format, isToday } from 'date-fns';
import { Container } from '../../Atoms';
import { useFindMeetings } from '../../graphql';

type Props = RouteComponentProps;

export const Meetings: React.FC<Props> = ({ location, history }) => {
  const parsedQueryString = queryString.parse(location.search);
  const hasParams = Object.keys(parsedQueryString).length > 0;

  const [element, setElement] = useState(null);
  const { isOpen, onToggle } = useDisclosure({ isOpen: hasParams });
  const loadingRef = useRef(null);
  const { data: findMeetingData, loading: findMeetingsLoading, fetchMore: fetchMoreFindMeetings } = useFindMeetings(
    10,
    0
  );

  const [
    searchMeetings,
    { data: searchMeetingsData, called, loading: searchMeetingsLoading, fetchMore: fetchMoreSearchedMeetings },
  ] = useLazyQuery<SearchMeetingsQuery, SearchMeetingsQueryVariables>(SEARCH_MEETINGS);

  useEffect(() => {
    if (hasParams) {
      searchMeetings({
        variables: {
          input: parsedQueryString,
          limit: 20,
          offset: 0,
        },
      });
    }
  }, []);

  const data = called ? searchMeetingsData : findMeetingData;
  const loading = called ? searchMeetingsLoading : findMeetingsLoading;

  useEffect(() => {
    if (!loading) {
      loadingRef.current = called ? searchMeetingsData.searchMeetings.length : findMeetingData.findMeetings.length;
    }
  }, [loading, data]);

  const handleFetchMoreSearched = () => {
    return fetchMoreSearchedMeetings({
      variables: {
        offset: loadingRef.current as number,
        limit: 5,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          findMeetings: [...prev.searchMeetings, ...fetchMoreResult.searchMeetings],
        });
      },
    });
  };

  const handleFetchMoreFind = () => {
    return fetchMoreFindMeetings({
      variables: {
        offset: loadingRef.current as number,
        limit: 5,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          findMeetings: [...prev.findMeetings, ...fetchMoreResult.findMeetings],
        });
      },
    });
  };

  const observer = useRef(
    new IntersectionObserver(
      entries => {
        const first = entries[0];

        if (first.isIntersecting) {
          return called ? handleFetchMoreSearched() : handleFetchMoreFind();
        }
      },
      { threshold: 1 }
    )
  );

  useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [element]);

  const setSearchQuery = (query: string) => {
    return history.push({
      pathname: '/meetings',
      search: `?${query}`,
    });
  };
  const getQueryString = (values: any) => {
    return queryString.stringify(values);
  };

  const handleSearchMeetings = async ({ city, country, startingDate, tags }: SearchMeetingInput) => {
    //@ts-ignore
    const formatedStartingDate = startingDate ? format(startingDate, 'yyyy-MM-dd') : '';

    const input = {
      city,
      country,
      startingDate: formatedStartingDate,
      tags,
    };

    searchMeetings({
      variables: {
        input,
        limit: 20,
        offset: 0,
      },
    });

    const query = getQueryString(input);

    setSearchQuery(query);
  };

  if (findMeetingsLoading || searchMeetingsLoading) return <Spinner />;

  const meetingsToView = called ? searchMeetingsData.searchMeetings : findMeetingData.findMeetings;

  const getTags = (tags: any) => {
    if (Array.isArray(tags)) {
      return tags;
    } else if (!!tags) {
      return [tags];
    } else {
      return [];
    }
  };

  const initialValues = {
    city: parsedQueryString.city || '',
    country: parsedQueryString.country || '',
    startingDate: parsedQueryString.startingDate ? new Date(parsedQueryString.startingDate as string) : '',
    tags: getTags(parsedQueryString.tags),
  } as any;

  return (
    <Box>
      <Box mb={8}>
        <Heading textAlign="center">Find Coworking sessions</Heading>
      </Box>
      <Container>
        <Flex direction="column" mb={8}>
          <Flex justify="flex-end">
            <Button onClick={onToggle} colorScheme={isOpen ? 'blue' : 'gray'}>
              <Icon as={MdFilterList} size="24px" />
              <Text ml={2}>Filters</Text>
            </Button>
          </Flex>
          <Divider marginY={6} />
          <Flex>
            {isOpen && (
              <Formik initialValues={initialValues} onSubmit={async v => handleSearchMeetings(v)}>
                {({ handleSubmit, values }) => (
                  <Form translate="">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                      flexDir={['column', 'row']}
                    >
                      <Stack isInline spacing={8} flex="1">
                        <Stack spacing={4} w="50%">
                          <Field name="city">
                            {({ field, form }: any) => {
                              return (
                                <FormControl width="100%" mr={6} mb={6}>
                                  <Flex justify="space-between">
                                    <FormLabel htmlFor="city">City</FormLabel>
                                    {field.value !== '' && (
                                      <Button
                                        variant="link"
                                        onClick={() => {
                                          form.setFieldValue('city', '');
                                          handleSearchMeetings({ ...values, city: '' });
                                        }}
                                      >
                                        Clear
                                      </Button>
                                    )}
                                  </Flex>
                                  <Input {...field} id="city" placeholder="City" />
                                  <FormErrorMessage>{form.errors.city}</FormErrorMessage>
                                </FormControl>
                              );
                            }}
                          </Field>

                          <Field name="country">
                            {({ field, form }: any) => (
                              <FormControl width="100%" mr={6} mb={6}>
                                <Flex justify="space-between">
                                  <FormLabel htmlFor="country">Country</FormLabel>
                                  {field.value !== '' && (
                                    <Button
                                      variant="link"
                                      type="button"
                                      onClick={() => {
                                        form.setFieldValue('country', '');
                                        handleSearchMeetings({ ...values, country: '' });
                                      }}
                                    >
                                      Clear
                                    </Button>
                                  )}
                                </Flex>
                                <Input {...field} id="country" placeholder="Country" />
                                <FormErrorMessage>{form.errors.country}</FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>
                        <Stack spacing={4} w="50%">
                          <Field name="startingDate">
                            {({ field, form }: any) => (
                              <FormControl width="100%" display="flex" flexDir="column" mr={6} mb={6}>
                                <Flex justify="space-between">
                                  <FormLabel htmlFor="startingDate">Date</FormLabel>
                                  {!!field.value && (
                                    <Button
                                      variant="link"
                                      onClick={() => {
                                        form.setFieldValue('startingDate', '');
                                        handleSearchMeetings({ ...values, startingDate: '' });
                                      }}
                                    >
                                      Reset
                                    </Button>
                                  )}
                                </Flex>
                                <DateInput {...field} minDate={new Date()} inputStyle={{ id: 'startingDate' }} />

                                <FormErrorMessage>{form.errors.city}</FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                          <Field
                            name="tags"
                            isMulti
                            formLabel="Tags"
                            placeholder="Tags"
                            helperText="Search with multiple tags"
                            formatCreateLabel={(userInput: string) => `Add tag: "${userInput}"`}
                            noOptionsMessage={() => 'Add some tags!'}
                            options={values.tags.map((tag: any) => ({ label: tag, value: tag }))}
                            as={MultiSelectInput}
                          />
                        </Stack>
                      </Stack>
                      <Box w={['100%', 'auto']} mt={[4, 0]}>
                        <Button type="submit" colorScheme="teal" w={['100%', 'auto']}>
                          Search
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            )}
          </Flex>
        </Flex>
        {meetingsToView.length === 0 ? (
          <Container height="100%" display="flex" justifyContent="center">
            <Flex direction="column" align="center" justify="center">
              <Heading as="h3" size="lg" mb={4}>
                No results found
              </Heading>
              <Text textAlign="center">
                It seems we can’t find any meetings based on your search. Try spicing it up! 🔥
              </Text>
            </Flex>
          </Container>
        ) : (
          <List>
            {meetingsToView.map((meeting, index) => (
              <Link key={meeting.title + index} to={`/meeting/${meeting.id}`}>
                <Box
                  _hover={{
                    cursor: 'pointer',
                  }}
                  rounded="lg"
                  boxShadow="xl"
                  mb={6}
                  px={5}
                  py={8}
                >
                  <Stack spacing={8}>
                    <Stack spacing={3}>
                      <Heading as="h4" fontSize="2xl">
                        {meeting.title}
                      </Heading>
                    </Stack>
                    {!!meeting.pictureUrl && <Image src={meeting.pictureUrl} h="250px" objectFit="cover" />}
                    <Text fontWeight="bold" color="gray.500">
                      Buddies
                    </Text>
                    <AvatarGroup size="md" max={2}>
                      {meeting.attendees.map((attendee, i) => (
                        <Avatar name={attendee.user.username} key={i} src={attendee.user.profileImage} />
                      ))}
                    </AvatarGroup>
                    {!!meeting.tags.length && (
                      <Flex direction="column">
                        <Text fontWeight="bold" color="gray.500" mb={2}>
                          Tags
                        </Text>
                        <Stack isInline flexWrap="wrap">
                          {meeting.tags.map((tag, i) => (
                            <Tag key={i} rounded="full" colorScheme="cyan" mr={4} mb={4}>
                              {tag.name}
                            </Tag>
                          ))}
                        </Stack>
                      </Flex>
                    )}
                  </Stack>
                </Box>
              </Link>
            ))}
            {!searchMeetingsLoading && <ListItem ref={setElement}>{searchMeetingsLoading && <Spinner />}</ListItem>}
          </List>
        )}
      </Container>
    </Box>
  );
};
