import { useQuery } from '@apollo/react-hooks';

import { ViewMeetingQuery, ViewMeetingQueryVariables } from '../generated/graphql';
import { VIEW_MEETING } from '../graphql/queries';

export const useViewMeeting = (meetingId: number, toUpdate: boolean) => {
  const query = useQuery<ViewMeetingQuery, ViewMeetingQueryVariables>(VIEW_MEETING, {
    variables: {
      id: meetingId,
      toUpdate,
    },
  });

  return query;
};
