import React from 'react';
import { Box, Link, VStack, ModalProps, SimpleGrid } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { FiPlus } from 'react-icons/fi';
import { IoIosLaptop } from 'react-icons/io';
import { Link as RLink } from 'react-router-dom';

import { LinkButton } from '../../../../Molecules';
import { User } from '../../../../types';

type Props = {
  isOpen: ModalProps['isOpen'];
  onClose: ModalProps['onClose'];
  user: User;
};

export const NavbarModal = ({ isOpen, onClose, user }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Coworkingbuddies</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid columns={2} gap={6}>
            <Link
              d="flex"
              alignItems="center"
              p={3}
              as={RLink}
              to={user ? '/create-meeting' : '/become-a-buddy'}
              _hover={{ bg: 'gray.100' }}
              rounded="md"
            >
              <FiPlus fontSize="1.5rem" />
              <Box marginInlineStart={3} fontWeight="medium">
                Create Meeting
              </Box>
            </Link>
            <Link d="flex" alignItems="center" p={3} as={RLink} to="/meetings" _hover={{ bg: 'gray.100' }} rounded="md">
              <IoIosLaptop fontSize="1.5rem" />
              <Box marginInlineStart={3} fontWeight="medium">
                Meetings
              </Box>
            </Link>
          </SimpleGrid>
          <VStack mt={8} spacing={4}>
            {user ? (
              <>
                <LinkButton to="/account" colorScheme="blue" isFullWidth mb={2} onClick={onClose}>
                  Profile
                </LinkButton>
                <Link as={RLink} to="/logout" color="blue.400">
                  Logout
                </Link>
              </>
            ) : (
              <>
                <LinkButton to="/register" colorScheme="blue" isFullWidth mb={2} onClick={onClose}>
                  Register
                </LinkButton>
                <Box>
                  Have an account?{' '}
                  <Link as={RLink} to="/login" color="blue.400">
                    Login
                  </Link>
                </Box>
              </>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
