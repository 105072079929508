import * as React from 'react';
import { Box, Link, SimpleGrid, SimpleGridProps, Stack } from '@chakra-ui/react';
import { Link as RLink } from 'react-router-dom';

import { FooterHeading } from './FooterHeading';

export const LinkGrid = (props: SimpleGridProps) => (
  <SimpleGrid columns={2} {...props}>
    <Box minW="130px">
      <FooterHeading mb="4">Product</FooterHeading>
      <Stack>
        <Link as={RLink} to="/">
          Our story
        </Link>
      </Stack>
    </Box>
    <Box minW="130px">
      <FooterHeading mb="4">Legal</FooterHeading>
      <Stack>
        <Link isExternal href="https://www.termsfeed.com/live/9637debe-145d-44d3-8bf7-6cb497729f06">
          Privacy
        </Link>
        {/* <Link>Terms</Link>
               <Link>License</Link> */}
      </Stack>
    </Box>
  </SimpleGrid>
);
