import { ShareModal, SignUpModal } from '../../components';
import { ManageBuddiesModal } from '../../Routes/ViewMeeting/components';

interface IModals {
  [key: string]: {
    component: React.FC<any>;
  };
}

export const modals = {
  shareModal: {
    component: ShareModal,
  },
  signUpModal: {
    component: SignUpModal,
  },
  manageBuddiesModal: {
    component: ManageBuddiesModal,
  },
} as IModals;
