import gql from 'graphql-tag';
import { ERROR_ATTRIBUTES, MEETING_ATTRIBUTES } from '../fragments';

export const createMeetingMutation = gql`
  mutation CreateMeeting($data: MeetingInput!) {
    createMeeting(data: $data) {
      ... on Error {
        path
        message
      }
      ... on Meeting {
        ...meetingResults
      }
    }
  }
  ${MEETING_ATTRIBUTES}
`;

export const ADD_TO_MEETING_MUTATION = gql`
  mutation AddToMeeting($meetingId: String!, $userId: String!, $hasAccepted: Boolean!) {
    addToMeeting(meetingId: $meetingId, userId: $userId, hasAccepted: $hasAccepted) {
      ... on Meeting {
        ...meetingResults
      }

      ... on Error {
        ...errorResults
      }
    }
  }
  ${ERROR_ATTRIBUTES}
  ${MEETING_ATTRIBUTES}
`;

export const REMOVE_FROM_MEETING = gql`
  mutation RemoveFromMeeting($meetingId: String!, $userId: String!) {
    removeFromMeeting(meetingId: $meetingId, userId: $userId) {
      ... on Meeting {
        ...meetingResults
      }

      ... on Error {
        ...errorResults
      }
    }
  }
  ${ERROR_ATTRIBUTES}
  ${MEETING_ATTRIBUTES}
`;

export const CREATE_MEETING_COMMENT = gql`
  mutation CreateMeetingComment($text: String!, $meetingId: Float!, $parentId: Float) {
    createMeetingComment(text: $text, meetingId: $meetingId, parentId: $parentId) {
      ... on Meeting {
        ...meetingResults
      }

      ... on Error {
        ...errorResults
      }
    }
  }
  ${ERROR_ATTRIBUTES}
  ${MEETING_ATTRIBUTES}
`;

export const DELETE_MEETING_COMMENT = gql`
  mutation DeleteMeetingComment($commentId: Float!, $meetingId: Float!) {
    deleteMeetingComment(commentId: $commentId, meetingId: $meetingId) {
      ... on Meeting {
        ...meetingResults
      }

      ... on Error {
        ...errorResults
      }
    }
  }
  ${ERROR_ATTRIBUTES}
  ${MEETING_ATTRIBUTES}
`;

export const UPDATE_MEETING = gql`
  mutation UpdateMeeting($input: MeetingInput!, $meetingId: String!) {
    updateMeeting(input: $input, meetingId: $meetingId) {
      ... on Error {
        ...errorResults
      }
    }
  }
  ${ERROR_ATTRIBUTES}
`;

export const UPSERT_MEETING_INVITATION = gql`
  mutation UpsertMeetingInvitation($meetingId: String!) {
    upsertMeetingInvitation(meetingId: $meetingId) {
      ... on Meeting {
        id
        title
        description
        pictureUrl
        endingDate
        startingDate
        tags {
          name
        }
        location {
          addressLine
          city
          country
        }
        attendees {
          hasAccepted
          user {
            username
          }
        }
      }
      ... on Error {
        ...errorResults
      }
    }
  }
  ${ERROR_ATTRIBUTES}
`;
