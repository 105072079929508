import { gql } from 'apollo-boost';

export const MEETING_ATTRIBUTES = gql`
  fragment meetingResults on Meeting {
    id
    title
    description
    pictureUrl
    location {
      city
      country
      addressLine
    }
    comments {
      id
      content
      path
      userId
      parentId
      profileImage
      firstName
      lastName
    }
    startingDate
    endingDate
    tags {
      name
    }
    attendees {
      isOwner
      hasAccepted
      user {
        email
        username
        profileImage
      }
    }
  }
`;
