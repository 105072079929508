import { gql } from 'apollo-boost';

export const SIGN_S3 = gql`
  mutation SignS3($fileName: String!, $fileType: String!) {
    signS3(filename: $fileName, filetype: $fileType) {
      url
      signedRequest
    }
  }
`;
