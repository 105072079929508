const modalNames = {
  SHARE_MODAL: 'shareModal',
  SIGN_UP_MODAL: 'signUpModal',
  MANAGE_BUDDIES_MODAL: 'manageBuddiesModal',
};

const excludedGAPaths = ['logout'];

const GLOBAL_MEDIA_QUERIES = {
  small: '(max-width: 599px)',
  medium: '(min-width: 600px) and (max-width: 1199px)',
  large: '(min-width: 1200px)',
};

export { modalNames, excludedGAPaths, GLOBAL_MEDIA_QUERIES };
