import React from 'react';
import { Box, Heading, Flex, Image, Text, Grid, Container } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import buddiesOne from '../../assets/images/landing-pic-1.jpg';
import buddiesTwo from '../../assets/images/landing-pic-2.jpg';
import { LinkButton } from '../../Molecules';

type Promotion = {
  name: string;
  text: string;
  title: string;
};

const promotions = [
  {
    name: 'Adam Lindqvist',
    text: `"The school environment is dull and rather boring. Being a buddy has allowed me to explore new and fun areas as well as meet people that have helped me in my game development studies."`,
    title: 'Studying Game Design',
  },
  {
    name: 'Andreas Sterner',
    text:
      "Coworkingbuddies has drastically changed my way of working outside of the office. I didn't realise how much having people working around me affected my workflow and dedication. I would recommend Coworkingbuddies to anyone who's looking to step up their workflow and dedication.",
    title: 'Security Engineer',
  },
];

type Props = {
  promotion: Promotion;
};

const PromotionCard: React.FC<Props> = ({ promotion }) => (
  <Flex bg="white" padding={6} direction="column" rounded="lg" maxW="2xl">
    <Heading as="h3" size="sm" mb={2}>
      {promotion.name}
    </Heading>
    <Heading as="h3" size="sm" mb={6} fontWeight="normal">
      {promotion.title}
    </Heading>
    <Text as="cite" fontSize="xl">
      {promotion.text}
    </Text>
  </Flex>
);

export const Landing: React.FC<{}> = () => {
  return (
    <Box>
      <Box bg="gray.100">
        <Container maxW="100ch" py={20}>
          <Grid
            templateColumns={['1fr', '1fr', '55% 40%']}
            gap="5%"
            margin="0 auto"
            justifyContent="self-end"
            maxW="80rem"
          >
            <Flex direction="column" justify="center" align="end" mb={['1rem', 0]}>
              <Heading as="h1" size="xl" lineHeight="shorter" mb={2}>
                Coworkingbuddies makes every place an office
              </Heading>
              <Text fontSize="xl" mb={8}>
                Create your own office with like minded buddies. Forget your old fashioned working space and come
                outside.
              </Text>
              <LinkButton to="/register" colorScheme="teal" size="lg">
                Become a buddy
              </LinkButton>
            </Flex>
            <Flex justify={['center', 'center', 'flex-end']}>
              <Image w="100%" maxW="600px" h="auto" objectFit="cover" rounded="lg" src={buddiesOne} />
            </Flex>
          </Grid>
        </Container>
      </Box>
      <Box>
        <Container maxW="100ch" py={20}>
          <Grid
            templateColumns={['1fr', '1fr', '40% 55%']}
            gap="5%"
            margin="0 auto"
            justifyContent="self-end"
            maxW="80rem"
          >
            <Flex justify={['center', 'center', 'flex-end']}>
              <Image w="100%" maxW="600px" h="auto" objectFit="cover" rounded="lg" src={buddiesTwo} />
            </Flex>
            <Flex direction="column" justify="center" align="end" mb={['1rem', 0]}>
              <Heading as="h1" size="xl" lineHeight="shorter" mb={2}>
                What is Coworkingbuddies?
              </Heading>
              <Text fontSize="xl" mb={8}>
                Coworkingbuddies is the outcome if Airbnb and WeWork had a baby together. As a buddy, you can create and
                attend coworking sessions everywhere. At a cafe, park, library, pizzeria or even at an office 😮!
              </Text>
              <Text fontSize="xl" mb={8}>
                It’s completely free and you always find new exiting coworking sessions via the{' '}
                <Link to="/meetings" style={{ color: '#319795' }}>
                  platform
                </Link>
                .
              </Text>
            </Flex>
          </Grid>
        </Container>
      </Box>
      <Box bg="gray.100">
        <Container maxW="100ch" py={20}>
          <Heading as="h1" size="xl" lineHeight="shorter" textAlign="center" mb={2}>
            What other buddies say about it
          </Heading>
          <Text fontSize="xl" textAlign="center" maxW="50rem" margin="0 auto 2rem auto">
            People from every industry, career, work-style has begun using Coworkingbuddies as their primary way to find
            buddies to work with.
          </Text>
          <Box maxW={['80vw', '90vw']} margin="0 auto">
            <Carousel autoPlay={10000} animationSpeed={1000} infinite>
              {promotions.map((promotion, index) => (
                <PromotionCard key={index} promotion={promotion} />
              ))}
            </Carousel>
          </Box>
        </Container>
      </Box>
      <Box bg="teal.500" boxShadow="inner">
        <Container maxW="100ch" py={20}>
          <Flex direction="column">
            <Flex direction="column" align="center" justify="center" mb={['1rem', 0]}>
              <Heading as="h3" size="xl" color="white" mb={8}>
                Become a Buddy
              </Heading>
              <LinkButton to="/register" size="lg">
                Join now
              </LinkButton>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};
