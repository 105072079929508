import React, { forwardRef, useContext } from 'react';
import { FiLogIn, FiUser } from 'react-icons/fi';
import {
  Drawer as CDrawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useColorMode,
  Flex,
  Box,
  LinkProps,
  ButtonProps,
  Button,
  Stack,
  Heading,
} from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import { useMedia } from 'react-media';

import { DrawerContext, useUser } from '../../contexts';
import { User } from '../../types';
import { GLOBAL_MEDIA_QUERIES } from '../../constants';

type LinkButtonProps = LinkProps & ButtonProps & { to: string; isActive?: boolean };

export const LinkButtonTest: React.FC<LinkButtonProps> = forwardRef(
  ({ isActive, ...props }: LinkButtonProps, ref: any) => {
    const activeStyles = isActive
      ? {
          bg: 'teal.50',
          _focus: {
            boxShadow: 'outline',
          },
        }
      : {};
    return (
      <Button
        ref={ref}
        as={Link}
        variant="ghost"
        colorScheme="teal"
        color="gray.700"
        display="flex"
        fontWeight="normal"
        justifyContent="flex-start"
        {...activeStyles}
        {...props}
      />
    );
  }
);

type StackLink = {
  to: string;
  text: string;
  isAuth: boolean;
};

type StackLinkProps = React.FC<{ pathname: string } & Omit<StackLink, 'isAuth'>>;

const StackLink: StackLinkProps = ({ to, pathname, text }) => (
  <LinkButtonTest to={to} isActive={to === pathname}>
    {text}
  </LinkButtonTest>
);

const color = {
  default: {
    dark: 'gray.400',
    light: 'gray.600',
  },
  hover: {
    dark: 'gray.300',
    light: 'gray.500',
  },
};

const UDrawerFooter: React.FC<{ user: User; colorMode: 'dark' | 'light' }> = ({ user, colorMode }) => {
  const Icon = user ? FiUser : FiLogIn;
  const text = user ? user.username : 'Log in';
  const to = user ? `/buddy/@${user.username}` : '/login';

  return (
    <>
      <Flex color={color.default[colorMode]}>
        <LinkButtonTest to={to} leftIcon={<Icon />}>
          {text}
        </LinkButtonTest>
      </Flex>
      {!!user && (
        <Flex color={color.default[colorMode]}>
          <LinkButtonTest to="/logout">Logout</LinkButtonTest>
        </Flex>
      )}
    </>
  );
};

const baseMeetingLinks = [
  {
    to: '/meetings',
    text: 'Browse',
    isAuth: false,
  },
  {
    to: '/meeting-invites',
    text: 'Invites',
    isAuth: true,
  },
  {
    to: '/upcoming-meetings',
    text: 'Upcoming',
    isAuth: true,
  },
];
const createMeetingLink = { to: 'create-meeting', text: 'Create', isAuth: false };

export const Drawer = () => {
  const { isDrawerOpen, toggleDrawer } = useContext(DrawerContext);
  const { colorMode } = useColorMode();
  const isSmallScreen = useMedia({ query: GLOBAL_MEDIA_QUERIES['small'] });
  const { user } = useUser();
  const {
    location: { pathname },
  } = useHistory();

  const meetingLinks = isSmallScreen ? [createMeetingLink, ...baseMeetingLinks] : baseMeetingLinks;

  // const Icon = colorMode === 'dark' ? FiSun : FaMoon;
  // const ariaLabel = `Switch to ${colorMode === 'dark' ? 'light' : 'dark'} mode`;

  return (
    <>
      <CDrawer isOpen={isDrawerOpen} placement="right" onClose={toggleDrawer}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader />
          <DrawerBody>
            <Stack>
              <Heading as="h4" size="md">
                Meetings
              </Heading>
              <Stack spacing={3}>
                {meetingLinks.map(({ isAuth, ...meetingLink }, i) =>
                  !isAuth ? (
                    <StackLink key={i} pathname={pathname} {...meetingLink} />
                  ) : !!user ? (
                    <StackLink key={i} pathname={pathname} {...meetingLink} />
                  ) : null
                )}
              </Stack>
            </Stack>
          </DrawerBody>
          <DrawerFooter justifyContent="space-between">
            <UDrawerFooter user={user} colorMode={colorMode} />
          </DrawerFooter>
        </DrawerContent>
      </CDrawer>
    </>
  );
};
