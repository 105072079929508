import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Heading,
  Flex,
  Text,
  Stack,
  Box,
  SimpleGrid,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { FIND_MM_MEETINGS } from '../../graphql/queries';
import { Container } from '../../Atoms';
import { Spinner, LinkButton } from '../../Molecules';
import {
  UpsertMeetingInvitationMutation,
  UpsertMeetingInvitationMutationVariables,
  RemoveFromMeetingMutation,
  RemoveFromMeetingMutationVariables,
  FindMmMeetingsQuery,
  FindMmMeetingsQueryVariables,
} from '../../generated/graphql';
import { formatMeetingTime } from '../../utils';
import { UPSERT_MEETING_INVITATION, REMOVE_FROM_MEETING } from '../../graphql/mutations';
import { useUser } from '../../contexts';

type Props = {};

type ConfirmationPopOverProps = {
  focusRef: React.MutableRefObject<undefined>;
  cancelFunction: () => void;
  confirmFunction: () => void;
  isOpen: boolean;
};

const ConfirmationPopOver: React.FC<ConfirmationPopOverProps> = ({
  focusRef,
  cancelFunction,
  confirmFunction,
  isOpen,
  children,
}) => {
  return (
    <Popover isOpen={isOpen} initialFocusRef={focusRef}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent zIndex={4}>
        <PopoverHeader fontWeight="semibold">Confirmation</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton onClick={cancelFunction} />
        <PopoverBody>Are you sure you want to continue with your action?</PopoverBody>
        <PopoverFooter d="flex" justifyContent="flex-end">
          <ButtonGroup size="sm">
            <Button variant="outline" onClick={cancelFunction} ref={focusRef}>
              No
            </Button>
            <Button colorScheme="red" onClick={confirmFunction}>
              Yes
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

const InviteCard: React.FC<{
  meetingInvite: any;
  upsertMeetingInvitation: (meetingId: string) => Promise<void>;
  handleRemoveFromMeeting: (meetingId: string) => Promise<void>;
}> = ({ meetingInvite, upsertMeetingInvitation, handleRemoveFromMeeting }) => {
  const initialFocusRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box p={5} shadow="md" borderWidth="1px" flex="1" rounded="md">
      <Text fontSize="2xl" fontWeight="semibold">
        {meetingInvite.title}
      </Text>
      <Stack spacing={2} mt={3}>
        <Text>
          <Text as="span" fontWeight="semibold">
            Where:{' '}
          </Text>
          {meetingInvite.location.addressLine}, {meetingInvite.location.country}, {meetingInvite.location.city}
        </Text>
        <Text>
          <Text as="span" fontWeight="semibold">
            When:{' '}
          </Text>
          {formatMeetingTime({ startingDate: meetingInvite.startingDate, endingDate: meetingInvite.endingDate })}
        </Text>
        <Text>
          <Text as="span" fontWeight="semibold">
            Buddies coming:{' '}
          </Text>
          {meetingInvite.attendees.filter(({ hasAccepted }: { hasAccepted: boolean }) => hasAccepted).length}
        </Text>
      </Stack>
      <Flex mt={4}>
        <Stack isInline spacing={2}>
          <Button colorScheme="teal" onClick={() => upsertMeetingInvitation(meetingInvite.id)}>
            Attend
          </Button>
          <ConfirmationPopOver
            focusRef={initialFocusRef}
            cancelFunction={onClose}
            confirmFunction={() => handleRemoveFromMeeting(meetingInvite.id)}
            isOpen={isOpen}
          >
            <Button colorScheme="red" onClick={onOpen}>
              Decline
            </Button>
          </ConfirmationPopOver>
          <LinkButton ml={2} to={`/meeting/${meetingInvite.id}`}>
            View
          </LinkButton>
        </Stack>
      </Flex>
    </Box>
  );
};

export const MeetingInvites: React.FC<Props> = () => {
  const { user } = useUser();
  const toast = useToast();

  const { data, loading } = useQuery<FindMmMeetingsQuery, FindMmMeetingsQueryVariables>(FIND_MM_MEETINGS, {
    variables: {
      limit: 10,
      offset: 0,
      hasAccepted: false,
    },
  });
  const [upsertMeetingInvitation] = useMutation<
    UpsertMeetingInvitationMutation,
    UpsertMeetingInvitationMutationVariables
  >(UPSERT_MEETING_INVITATION);
  const [removeFromMeeting] = useMutation<RemoveFromMeetingMutation, RemoveFromMeetingMutationVariables>(
    REMOVE_FROM_MEETING
  );

  if (loading) return <Spinner />;

  const showToast = (message: string, title: string, status: 'success' | 'warning') =>
    toast({
      title: title,
      description: message,
      status: status,
      duration: 5000,
      isClosable: true,
      position: 'top-right',
    });

  const onUpsertMeetingInvitation = async (meetingId: string) => {
    await upsertMeetingInvitation({
      variables: {
        meetingId,
      },
      refetchQueries: [
        {
          query: FIND_MM_MEETINGS,
          variables: {
            limit: 10,
            offset: 0,
            hasAccepted: false,
          },
        },
      ],
    });
  };
  const handleRemoveFromMeeting = async (meetingId: string) => {
    const {
      data: {
        removeFromMeeting: [r],
      },
    } = await removeFromMeeting({
      variables: {
        meetingId,
        userId: user.username,
      },
      refetchQueries: [
        {
          query: FIND_MM_MEETINGS,
          variables: {
            limit: 10,
            offset: 0,
            hasAccepted: false,
          },
        },
      ],
    });

    if (r.__typename === 'Error') {
      showToast(r.message, 'Uho', 'warning');
    } else {
      showToast('Your invitation has been removed', 'Success', 'success');
    }
  };

  const invites = data.findMMMeetings;

  return (
    <Container>
      <Heading textAlign="center" mb={6}>
        Meeting invites ({invites.length})
      </Heading>
      {invites.length === 0 && (
        <Flex direction="column" align="center" justify="center">
          <Text textAlign="center">It's empty here</Text>
        </Flex>
      )}
      <SimpleGrid columns={[1, 3]} spacing={10}>
        {invites.map(meetingInvite => (
          <InviteCard
            key={meetingInvite.id}
            meetingInvite={meetingInvite}
            upsertMeetingInvitation={onUpsertMeetingInvitation}
            handleRemoveFromMeeting={handleRemoveFromMeeting}
          />
        ))}
      </SimpleGrid>
    </Container>
  );
};
