import React from 'react';
import { Grid, Box, useColorMode } from '@chakra-ui/react';

interface Props {
  header?: React.ReactNode;
  main: React.ReactNode;
  footer?: React.ReactNode;
}

export const PageLayout = ({ header, main, footer }: Props) => {
  const { colorMode } = useColorMode();
  const bg = { light: 'white', dark: 'gray.800' };

  return (
    <Grid
      h="100vh"
      bg={bg[colorMode]}
      templateAreas={`
        "header"
        "main"
        "footer"
      `}
      templateColumns="1fr"
      templateRows="auto 1fr auto"
    >
      {header && (
        <Grid area="header" position="sticky" top={0} zIndex={10}>
          {header}
        </Grid>
      )}
      <Grid area="main">
        <Box>{main}</Box>
      </Grid>
      {footer && <Grid area="footer">{footer}</Grid>}
    </Grid>
  );
};
