import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Input } from '@chakra-ui/react';

import 'react-datepicker/dist/react-datepicker.css';
import { FieldAttributes, useField, useFormikContext } from 'formik';

type Props = FieldAttributes<ReactDatePickerProps> & { inputStyle: any };

export const DateInput: React.FC<Props> = ({
  showTimeSelect,
  showTimeSelectOnly,
  dateFormat,
  minDate,
  maxDate,
  minTime,
  maxTime,
  timeFormat,
  inputStyle,
  ...props
}) => {
  //@ts-ignore
  const [field] = useField<{}>(props);
  const { setFieldValue } = useFormikContext();

  return (
    <ReactDatePicker
      selected={field.value as Date}
      onChange={date => setFieldValue(props.name, date)}
      customInput={<Input {...inputStyle} />}
      showTimeSelect={showTimeSelect}
      showTimeSelectOnly={showTimeSelectOnly}
      dateFormat={dateFormat}
      minDate={minDate}
      maxDate={maxDate}
      minTime={minTime}
      maxTime={maxTime}
      timeFormat={timeFormat}
    />
  );
};
