import * as React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button } from '@chakra-ui/react';

import { FormInput } from '../../../shared/components';

interface FormValues {
  email: string;
  username: string;
  password: string;
}

const initialValues: FormValues = {
  email: '',
  username: '',
  password: '',
};

const emailNotLongEnough = 'Email must be at least 3 characters';
const emailNotValid = 'Email must be a valid email';
const passwordNotLongEnough = 'Password must be at least 3 characters';
const usernameNotLongEnough = 'Username must be at least 3 characters';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .min(3, emailNotLongEnough)
    .max(255)
    .email(emailNotValid)
    .required(),
  username: yup
    .string()
    .min(3, usernameNotLongEnough)
    .max(15)
    .required(),
  password: yup
    .string()
    .min(3, passwordNotLongEnough)
    .max(255)
    .required(),
});

type Props = {
  handleRegister: (values: FormValues, setErrors: any) => Promise<any>;
};

export const RegisterForm: React.FC<Props> = ({ handleRegister }) => (
  <Formik
    initialValues={initialValues}
    validateOnChange={false}
    validateOnBlur={true}
    validationSchema={validationSchema}
    onSubmit={(values, actions) => {
      handleRegister(values, actions.setErrors);
      actions.setSubmitting(false);
    }}
  >
    {({ isSubmitting, handleSubmit, errors }) => (
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <FormInput isRequired name="username" variant="flushed" formLabel="Username" />
        <FormInput isRequired name="email" variant="flushed" formLabel="Email" />
        <FormInput isRequired name="password" type="password" variant="flushed" formLabel="Password" />
        <Button mt={4} colorScheme="teal" isLoading={isSubmitting} type="submit" isDisabled={isSubmitting}>
          Sign up
        </Button>
      </form>
    )}
  </Formik>
);
