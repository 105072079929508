import { gql } from 'apollo-boost';

export const VIEW_MEETING = gql`
  query ViewMeeting($id: Float!, $toUpdate: Boolean!) {
    viewMeeting(id: $id, toUpdate: $toUpdate) {
      id
      title
      description
      pictureUrl
      onlineLink
      location {
        country
        city
        addressLine
        latitude
        longitude
      }
      startingDate
      endingDate
      tags {
        name
      }
      attendees {
        isOwner
        hasAccepted
        user {
          email
          username
          profileImage
        }
      }
      comments {
        id
        content
        path
        userId
        parentId
        profileImage
        firstName
        lastName
      }
    }
  }
`;

export const VIEW_MEETING_TO_UPDATE = gql`
  query ViewMeetingToUpdate($id: Float!, $toUpdate: Boolean!) {
    viewMeeting(id: $id, toUpdate: $toUpdate) {
      id
      title
      description
      pictureUrl
      startingDate
      isPublic
      endingDate
      onlineLink
      attendees {
        user {
          username
        }
      }
      location {
        addressLine
        latitude
        longitude
      }
      tags {
        name
      }
    }
  }
`;

export const findMeetingsQuery = gql`
  query FindMeetingsQuery($limit: Float!, $offset: Float!) {
    findMeetings(limit: $limit, offset: $offset) {
      id
      title
      description
      pictureUrl
      tags {
        name
      }
      attendees {
        isOwner
        user {
          username
          profileImage
        }
      }
    }
  }
`;

export const SEARCH_MEETINGS = gql`
  query SearchMeetings($input: SearchMeetingInput!, $offset: Float!, $limit: Float!) {
    searchMeetings(input: $input, offset: $offset, limit: $limit) {
      id
      title
      description
      pictureUrl
      tags {
        name
      }
      attendees {
        isOwner
        user {
          username
          profileImage
        }
      }
    }
  }
`;

export const FIND_MM_MEETINGS = gql`
  query FindMMMeetings($offset: Float!, $limit: Float!, $hasAccepted: Boolean!) {
    findMMMeetings(offset: $offset, limit: $limit, hasAccepted: $hasAccepted) {
      id
      title
      endingDate
      startingDate
    }
  }
`;
