import React, { useRef, ChangeEvent, useEffect } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Input, List, ListItem, Text, Box, Flex } from '@chakra-ui/react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { FiMapPin } from 'react-icons/fi';

type Suggestion = google.maps.places.AutocompletePrediction;

type Props = {
  getCoordinates: (coordinates: { lat: number; lng: number }) => void;
  defaultValue?: string;
};

export const PlacesAutocomplete: React.FC<Props> = ({ getCoordinates, defaultValue }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    // requestOptions: { /* Define search scope here */ }
    debounce: 500,
  });

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue, false);
    }
  }, []);

  const ref = useRef();
  useOnclickOutside(ref, () => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };

  const handleSelect = ({ description }: Suggestion) => (): void => {
    setValue(description, false);
    clearSuggestions();

    getGeocode({ address: description })
      .then(results => getLatLng(results[0]))
      .then(coordinates => {
        getCoordinates(coordinates);
      })
      .catch(error => {
        console.log('😱 Error: ', error);
      });
  };

  const renderSuggestions = () =>
    data.map(suggestion => {
      const {
        id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <ListItem
          key={id}
          onClick={handleSelect(suggestion)}
          py={2}
          px={1}
          rounded="md"
          _hover={{ cursor: 'pointer', bg: 'gray.100' }}
        >
          <Flex align="center">
            <Box as={FiMapPin} mr={2} color="gray.600" />
            <Text as="span" fontWeight="bold">
              {main_text}
            </Text>
            <Text as="span" ml={2}>
              {secondary_text}
            </Text>
          </Flex>
        </ListItem>
      );
    });

  return (
    <Box ref={ref}>
      <Input value={value} onChange={handleInput} isDisabled={!ready} mb={2} placeholder="Search for a place" />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && <List spacing={4}>{renderSuggestions()}</List>}
    </Box>
  );
};
