import React from 'react';
import { useHistory } from 'react-router';
import { Button, ButtonProps } from '@chakra-ui/react';

type Props = { to: string } & ButtonProps;

export const LinkButton: React.FC<Props> = ({ to, onClick, ...rest }) => {
  const { push } = useHistory();
  return (
    <Button
      {...rest}
      onClick={(event: any) => {
        onClick && onClick(event);
        push(to);
      }}
    >
      {rest.children}
    </Button>
  );
};
