import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  List,
  ListItem,
  Box,
  useClipboard,
} from '@chakra-ui/react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import { FaFacebook, FaTwitter, FaLinkedinIn, FaRegCopy } from 'react-icons/fa';

import { IModalProps } from '../Providers';
import { Meeting } from '../generated/graphql';

type Props = {
  meeting: Meeting;
};

export const ShareModal: React.FC<IModalProps<Props>> = ({ closeModal, modalProps: { meeting } }) => {
  const shareUrl = location.href;

  const title = 'Check out this co-working session!';
  const twitterTitle = `Join these buddies co-working session: ${meeting.title} ${
    meeting.onlineLink ? '' : `#${meeting.location.city}`
  }  #Coworkingbuddies via @CoworkingBuddiz`;

  const { onCopy, hasCopied } = useClipboard(shareUrl);
  return (
    <Modal onClose={closeModal} isOpen={true} isCentered size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Share co-working session</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <List spacing={4}>
            <ListItem>
              <FacebookShareButton translate="" url={shareUrl} quote={title} style={{ width: '100%' }}>
                <Flex>
                  <Box size="28px" mr={3} color="gray.600" as={FaFacebook} />
                  <Text as="span" fontSize="lg">
                    Facebook
                  </Text>
                </Flex>
              </FacebookShareButton>
            </ListItem>
            <ListItem>
              <TwitterShareButton translate="" url={shareUrl} title={twitterTitle} style={{ width: '100%' }}>
                <Flex>
                  <Box size="28px" mr={3} color="gray.600" as={FaTwitter} />
                  <Text as="span" fontSize="lg">
                    Twitter
                  </Text>
                </Flex>
              </TwitterShareButton>
            </ListItem>
            <ListItem>
              <LinkedinShareButton translate="" url={shareUrl} title={title} style={{ width: '100%' }}>
                <Flex>
                  <Box size="28px" mr={3} color="gray.600" as={FaLinkedinIn} />
                  <Text as="span" fontSize="lg">
                    LinkedIn
                  </Text>
                </Flex>
              </LinkedinShareButton>
            </ListItem>
            <ListItem onClick={onCopy} cursor="pointer">
              <Flex>
                <Box size="28px" mr={3} color="gray.600" as={FaRegCopy} />
                <Text as="span" fontSize="lg">
                  {hasCopied ? 'Copied' : 'Copy link'}
                </Text>
              </Flex>
            </ListItem>
          </List>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
