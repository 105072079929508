import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Button,
  FormLabel,
  Input,
  FormControl,
  List,
  ListItem,
  Avatar,
} from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import { IModalProps } from '../../../Providers';
import {
  Meeting,
  SearchUsersQuery,
  SearchUsersQueryVariables,
  AddToMeetingMutationVariables,
  AddToMeetingMutation,
  RemoveFromMeetingMutation,
  RemoveFromMeetingMutationVariables,
} from '../../../generated/graphql';
import { SEARCH_USERS_USERNAME, VIEW_MEETING } from '../../../graphql/queries';
import { ADD_TO_MEETING_MUTATION, REMOVE_FROM_MEETING } from '../../../graphql/mutations';

type Props = IModalProps<{ meeting: Meeting }>;

export const ManageBuddiesModal: React.FC<Props> = ({ closeModal, modalProps: { meeting } }) => {
  const [value, setValue] = useState('');
  const [invitedAttendeesIds, setInvitedAttendeesIds] = useState(
    meeting.attendees.map(attendee => attendee.user.username)
  );

  const [search, { data, called }] = useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SEARCH_USERS_USERNAME);
  const [assignToMeeting, { loading }] = useMutation<AddToMeetingMutation, AddToMeetingMutationVariables>(
    ADD_TO_MEETING_MUTATION
  );
  const [removeFromMeeting] = useMutation<RemoveFromMeetingMutation, RemoveFromMeetingMutationVariables>(
    REMOVE_FROM_MEETING
  );

  const handleFilter = debounce((val: string) => {
    search({ variables: { username: val, limit: 5, offset: 0 } });
  }, 500);

  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
    target.value.length >= 3 && handleFilter(target.value);
  };

  const handleSendInvite = async (meetingId: string, userId: string) => {
    const {
      data: {
        addToMeeting: [a],
      },
    } = await assignToMeeting({
      variables: {
        meetingId,
        userId,
        hasAccepted: false,
      },
      update(cache) {
        cache.readQuery({
          query: VIEW_MEETING,
          variables: { id: Number(meetingId), toUpdate: false },
        });
      },
    });
    if (a.__typename !== 'Error') {
      setInvitedAttendeesIds(curr => curr.concat(userId));
    }
  };
  const handleRemoveInvite = async (meetingId: string, userId: string) => {
    const {
      data: {
        removeFromMeeting: [r],
      },
    } = await removeFromMeeting({
      variables: {
        meetingId,
        userId,
      },
      update(cache) {
        cache.readQuery({
          query: VIEW_MEETING,
          variables: { id: Number(meetingId), toUpdate: false },
        });
      },
    });
    if (r.__typename !== 'Error') {
      setInvitedAttendeesIds(curr => curr.filter(c => c !== userId));
    }
  };

  const attendees = meeting.attendees.filter(
    attendee => !attendee.isOwner && invitedAttendeesIds.includes(attendee.user.username)
  );

  return (
    <Modal onClose={closeModal} isOpen={true} isCentered size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Manage buddies for "{meeting.title}"</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={4}>
          <Flex direction="column" align="center">
            <FormControl w="100%" mb={4}>
              <FormLabel htmlFor="username">Search with username (min 3 characters)</FormLabel>
              <Input
                type="text"
                id="username"
                aria-describedby="username-helper-text"
                value={value}
                onChange={onChange}
              />
            </FormControl>
          </Flex>
          {!!data && !!data.searchUsers.length && (
            <List spacing={3} p={4} shadow="md">
              {data.searchUsers.map(user => {
                const alreadyInvited = invitedAttendeesIds.includes(user.username);
                return (
                  <ListItem key={user.username}>
                    <Flex align="center">
                      <Avatar size="md" name={user.fullName ? user.fullName : user.username} src={user.profileImage} />
                      <Text fontWeight={500} ml={2} flex="1">
                        {user.fullName ? user.fullName : user.username}
                      </Text>
                      <Button
                        colorScheme={alreadyInvited ? 'gray' : 'green'}
                        isLoading={loading}
                        isDisabled={alreadyInvited}
                        onClick={() =>
                          alreadyInvited
                            ? handleRemoveInvite(meeting.id, user.username)
                            : handleSendInvite(meeting.id, user.username)
                        }
                      >
                        {alreadyInvited ? 'Invited' : 'Invite'}
                      </Button>
                    </Flex>
                  </ListItem>
                );
              })}
            </List>
          )}
          {called && !!data && !data.searchUsers.length && (
            <Text fontSize="lg" textAlign="center">
              No buddies found 😔
            </Text>
          )}
          <Flex direction="column" mt={!!data ? 6 : 0}>
            <Text fontSize="lg" mb={2}>
              Buddies ({attendees.length})
            </Text>
            <List spacing={3}>
              {attendees.map(({ user }) => {
                const alreadyInvited = invitedAttendeesIds.includes(user.username);
                return (
                  <ListItem key={user.username}>
                    <Flex align="center">
                      <Avatar size="md" name={user.fullName ? user.fullName : user.username} src={user.profileImage} />
                      <Text fontWeight={500} ml={2} flex="1">
                        {user.fullName ? user.fullName : user.username}
                      </Text>
                      <Button
                        colorScheme={alreadyInvited ? 'red' : 'green'}
                        isLoading={loading}
                        onClick={() =>
                          alreadyInvited
                            ? handleRemoveInvite(meeting.id, user.username)
                            : handleSendInvite(meeting.id, user.username)
                        }
                      >
                        {alreadyInvited ? 'Remove buddy' : 'Invite'}
                      </Button>
                    </Flex>
                  </ListItem>
                );
              })}
            </List>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
