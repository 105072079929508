import * as React from 'react';
import { Heading, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { Container } from '../../Atoms';

export const NotFound: React.FC<{}> = () => (
  <Container h="100%" d="flex" justifyContent="center" flexDir="column">
    <Heading as="h1" fontSize={['3rem', '8rem']} fontWeight="bolder" mb={24}>
      Page not found.
    </Heading>
    <Text fontSize="lg" mb={4}>
      Something went wrong. Return to the home page to find what you are looking for.
    </Text>
    <Link to={'/meetings'} style={{ color: '#319795' }}>
      Go to homepage
    </Link>
  </Container>
);
