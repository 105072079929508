import { useQuery } from '@apollo/react-hooks';

import { FindMeetingsQueryQuery, FindMeetingsQueryQueryVariables } from '../../generated/graphql';
import { findMeetingsQuery } from '../queries';

export const useFindMeetings = (limit: number, offset: number) => {
  const query = useQuery<FindMeetingsQueryQuery, FindMeetingsQueryQueryVariables>(findMeetingsQuery, {
    variables: { limit, offset },
  });

  return query;
};
