import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Input, Text, Button, Image, useDisclosure, IconButton, Tooltip, FormLabel } from '@chakra-ui/react';
import { useField, FieldAttributes, useFormikContext } from 'formik';
import { FiImage, FiEdit2 } from 'react-icons/fi';

import { useDropzoneStyle } from './styles';

const getDropText = (isDragActive: boolean, isDragReject: boolean, isFileTooLarge: boolean) => {
  let text = "Drag 'n' drop an image here";

  if (isDragActive && !isDragReject) {
    text = "Drop it like it's hot!";
  } else if (isDragReject) {
    text = 'Image type not accepted, sorry!';
  } else if (isFileTooLarge) {
    text = 'Image is too large!';
  }
  return text;
};
const maxSize = 5242880;

type Props = FieldAttributes<{}> & {
  formLabel?: string;
};

export const DropzoneInput: React.FC<Props> = ({ formLabel, ...rest }) => {
  const [file, setFile] = useState(null);
  const { values } = useFormikContext<{ pictureUrl: string }>();
  const [, , helpers] = useField(rest);

  const preview = (file ? file.preview : null) || values.pictureUrl;

  const { isOpen, onOpen, onClose } = useDisclosure({ isOpen: !preview });

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open, rejectedFiles } = useDropzone({
    accept: 'image/*',
    noClick: true,
    noKeyboard: true,
    maxSize, // 5MB
    onDrop: ([file]) => {
      if (Boolean(file)) {
        helpers.setValue(file);
        setFile(Object.assign(file, { preview: URL.createObjectURL(file) }) as any);
        onClose();
      }
    },
  });

  const isFileTooLarge = !!rejectedFiles.length && rejectedFiles[0].size > maxSize;

  const style = useDropzoneStyle({
    isDragActive,
    isDragAccept,
    isDragReject,
    isFileTooLarge,
  });

  const dropText = getDropText(isDragActive, isDragReject, isFileTooLarge);

  return (
    <Box as="section" mb={6}>
      <FormLabel>{formLabel}</FormLabel>
      {isOpen ? (
        <Box {...(getRootProps({ style }) as any)} flexDir="column">
          <Input {...(getInputProps() as any)} />
          <Text color="gray.800" mb={4}>
            {dropText}
          </Text>
          <Button type="button" colorScheme="blue" variant="outline" leftIcon={<FiImage />} onClick={open}>
            Upload photo
          </Button>
        </Box>
      ) : (
        !!preview && (
          <Box>
            <Box position="relative">
              <Tooltip aria-label="Change image" label="Change image" placement="top">
                <IconButton
                  onClick={onOpen}
                  aria-label="Change image"
                  position="absolute"
                  colorScheme="blue"
                  zIndex={1}
                  top={4}
                  left={4}
                  icon={<FiEdit2 />}
                />
              </Tooltip>
              <Image alt="Meeting photo preview" src={preview} size="300px" rounded="md" />
            </Box>
          </Box>
        )
      )}
    </Box>
  );
};
