import * as React from 'react';
import { Redirect } from 'react-router';

import {
  ViewMeeting,
  Register,
  UpdateUser,
  ViewUser,
  Login,
  Logout,
  Meetings,
  ForgotPassword,
  ChangePassword,
  CreateMeeting,
  BecomeABuddy,
  ConfirmBuddy,
  Landing,
  UpdateMeeting,
  MeetingInvites,
  UpcomingMeetings,
} from '../../Routes/Pages';
import { Footer } from '../../shared/components';

interface IRoute {
  path: string;
  component: React.ComponentClass | any;
  exact?: boolean;
  unauthed?: React.ComponentClass;
  footer?: React.ComponentClass;
  nav?: boolean;
  title?: string;
}

export default [
  {
    path: '/',
    title: 'Coworkingbuddies | coworking sessions everywhere',
    component: Landing,
    nav: true,
    exact: true,
    footer: Footer,
  },
  {
    path: '/register',
    title: 'Join | Coworkingbuddies',
    component: Register,
    footer: Footer,
    nav: true,
  },
  {
    title: 'Login | Coworkingbuddies',
    path: '/login',
    component: Login,
    footer: Footer,
    nav: true,
    exact: true,
  },
  {
    title: 'Logout...',
    path: '/logout',
    component: Logout,
  },
  {
    title: 'Find and create coworking sessions',
    path: '/meetings',
    component: Meetings,
    nav: true,
    exact: true,
    footer: Footer,
  },
  // {
  //   path: '/projects',
  //   component: Projects,
  //   nav: true,
  //   exact: true,
  //   footer: Footer,
  // },
  {
    path: '/meeting/:meetingId',
    component: ViewMeeting,
    nav: true,
    exact: true,
    footer: Footer,
  },
  {
    path: '/meeting/:meetingId/edit',
    component: UpdateMeeting,
    nav: true,
    footer: Footer,
    unauthed: () => <Redirect to="/become-a-buddy" />,
  },
  // {
  //   path: '/project/:projectId',
  //   component: ViewProject,
  //   nav: true,
  //   footer: Footer,
  // },
  {
    title: 'Create new coworking session',
    path: '/create-meeting',
    component: CreateMeeting,
    unauthed: () => <Redirect to="/become-a-buddy" />,
    nav: true,
    footer: Footer,
  },
  {
    title: 'Meeting invites',
    path: '/meeting-invites',
    component: MeetingInvites,
    unauthed: () => <Redirect to="/login" />,
    nav: true,
    footer: Footer,
  },
  {
    title: 'Upcoming meetings',
    path: '/upcoming-meetings',
    component: UpcomingMeetings,
    unauthed: () => <Redirect to="/login" />,
    nav: true,
    footer: Footer,
  },
  // {
  //   path: '/inbox',
  //   component: Inbox,
  //   unauthed: () => <Redirect to="/login" />,
  //   nav: true,
  // },
  {
    path: '/buddy/@:username',
    component: ViewUser,
    nav: true,
    footer: Footer,
  },
  {
    title: 'Edit account | Coworkingbuddies',
    path: '/account',
    unauthed: () => <Redirect to="become-a-buddy" />,
    component: UpdateUser,
    nav: true,
    footer: Footer,
  },
  {
    title: 'Become a buddy!',
    path: '/become-a-buddy',
    component: BecomeABuddy,
    nav: true,
    footer: Footer,
  },
  {
    title: 'Forgot password',
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    title: 'Change password',
    path: '/buddy/change-password/:token',
    component: ChangePassword,
    exact: true,
  },
  {
    title: 'Confirm your account',
    path: '/buddy/confirm/:token',
    component: ConfirmBuddy,
    exact: true,
  },
  // {
  //   path: '/create-project',
  //   component: CreateProject,
  //   nav: true,
  //   footer: Footer,
  // },
] as Array<IRoute>;
