import React from 'react';
import { Box, useColorMode, Flex, Avatar, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { viewMeetingColors } from '../Routes/ViewMeeting';
import { MeetingMember } from '../generated/graphql';

type Props = {
  meetingMember: MeetingMember;
};

export const MeetingMemberCard: React.FC<Props> = ({ meetingMember }) => {
  const { colorMode } = useColorMode();
  const { user, isOwner, hasAccepted } = meetingMember;

  return (
    <Link key={user.username} to={`/buddy/@${user.username}`}>
      <Box
        marginRight={4}
        marginBottom={4}
        transition="all 0.2s"
        rounded="lg"
        backgroundColor={viewMeetingColors.card[colorMode]}
        _hover={{
          cursor: 'pointer',
          shadow: 'md',
          transition: 'all 0.2s',
        }}
      >
        <Flex direction="column" align="center" p={4} opacity={hasAccepted ? 1 : 0.6}>
          <Avatar size="lg" name={user.username} src={user.profileImage} />
          <Text mt={2} fontWeight="bold" color={viewMeetingColors.text[colorMode]}>
            {user.username}
          </Text>
          <Text color={viewMeetingColors.text[colorMode]}>
            {isOwner ? 'Owner' : !hasAccepted ? 'Pending' : 'Member'}
          </Text>
        </Flex>
      </Box>
    </Link>
  );
};
