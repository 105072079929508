import { gql } from 'apollo-boost';
import { USER_ATTRIBUTES } from '../fragments';

export const VIEW_USER = gql`
  query ViewUser($username: String!) {
    viewUser(username: $username) {
      email
      firstName
      lastName
      profileImage
      bio
      interestedIn
      location
      profession
      username
      meetings {
        id
        title
        description
        pictureUrl
      }
    }
  }
`;

export const SEARCH_USERS_USERNAME = gql`
  query SearchUsers($username: String!, $limit: Float!, $offset: Float!) {
    searchUsers(username: $username, limit: $limit, offset: $offset) {
      username
      fullName
      profileImage
    }
  }
`;

export const meQuery = gql`
  query Me {
    me {
      ...userResults
    }
  }
  ${USER_ATTRIBUTES}
`;
