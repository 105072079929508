import { addDays, eachDayOfInterval, format, isBefore, setHours, addHours, setMinutes, fromUnixTime } from 'date-fns';

export const getDaysRange = (amountOfDays: number) => {
  const today = new Date();

  return eachDayOfInterval({
    start: today,
    end: addDays(today, amountOfDays),
  });
};

export const getTimeRange = (start: Date) => {
  const now = new Date();
  const end = setHours(start, 24);

  let timeStops: string[] = [];

  const roundedMinutes = Math.ceil(now.getMinutes() / 30) * 30;

  let nowStop = now;

  const isToday = format(now, 'yyyy-MM-dd') === format(start, 'yyyy-MM-dd');

  if (isToday) {
    nowStop = isBefore(start, now) ? now : start;
  }

  nowStop = setMinutes(now, roundedMinutes);

  while (isBefore(nowStop, end)) {
    timeStops.push(format(nowStop, 'HH:mm'));
    nowStop = addHours(nowStop, 1);
  }

  return timeStops;
};

export const formatMeetingDate = ({
  startingDate,
  endingDate,
}: {
  startingDate: number;
  endingDate: number;
}): string => {
  const s = fromUnixTime(startingDate / 1000);
  const e = fromUnixTime(endingDate / 1000);
  return `${format(s, 'EEEE, MMMM d, yyyy kk:mm')} to ${format(e, 'kk:mm')}`;
};

export const toFormatedDate = (date: Date) => format(date, 'yyyy-MM-dd');
export const toFormatedTime = (date: Date) => format(date, 'HH:mm');

export const formatMeetingTime = ({
  startingDate,
  endingDate,
}: {
  startingDate: number;
  endingDate: number;
}): string => {
  const s = fromUnixTime(startingDate / 1000);
  const e = fromUnixTime(endingDate / 1000);
  return `${format(s, 'EEEE, MMMM d, yyyy kk:mm')} to ${format(e, 'kk:mm')}`;
};
