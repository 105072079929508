import * as React from 'react';
import { IUserContext } from '../types';

export const UserContext = React.createContext<IUserContext>({} as any);

export const useUser = () => {
  const ctx = React.useContext(UserContext);
  if (!ctx) {
    throw new Error('useUser must be within a UserProvider');
  }
  return ctx;
};
