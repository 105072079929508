import React from 'react';
import { ErrorMessage, Field, FieldAttributes, FieldProps } from 'formik';
import { Checkbox, CheckboxProps } from '@chakra-ui/checkbox';
import { FormControl, FormErrorMessage, FormErrorMessageProps, FormHelperText } from '@chakra-ui/form-control';

interface IFormCheckbox {
  name: string;
  formLabel?: string;
  helperText?: string;
  isRequired?: boolean;
  formErrorMessageProps?: FormErrorMessageProps;
}

type FormCheckboxProps = FieldAttributes<IFormCheckbox & CheckboxProps>;

export const FormCheckbox: React.FC<FormCheckboxProps> = ({
  name,
  formLabel,
  helperText,
  isRequired = false,
  formErrorMessageProps,
  ...rest
}) => {
  return (
    <Field>
      {({ field, form }: FieldProps) => (
        <FormControl isRequired={isRequired} isInvalid={!!form.errors[name]} mb={4}>
          <Checkbox id={name} {...(field as any)} {...rest}>
            {formLabel}
          </Checkbox>
          <ErrorMessage name={name}>
            {errorMessage => <FormErrorMessage {...formErrorMessageProps}>{errorMessage}</FormErrorMessage>}
          </ErrorMessage>
          {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
};
