import React from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

export type DefaultCenter = {
  lat: number;
  lng: number;
};

export const MapWithAMarker = withGoogleMap<{
  defaultCenter: DefaultCenter;
  lat: number;
  lng: number;
  zoom?: number;
}>(props => (
  <GoogleMap
    defaultZoom={props.zoom || 15}
    defaultCenter={{
      lat: props.lat,
      lng: props.lng,
    }}
  >
    <Marker position={{ lat: props.lat, lng: props.lng }} />
  </GoogleMap>
));
