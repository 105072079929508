import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { RouteComponentProps, Link, Redirect } from 'react-router-dom';
import {
  Box,
  Avatar,
  Heading,
  Flex,
  Text,
  Tag,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  SimpleGrid,
  Image,
} from '@chakra-ui/react';
import { FiBriefcase, FiGlobe } from 'react-icons/fi';
import Helmet from 'react-helmet';

import { useUser } from '../../contexts';
import { VIEW_USER } from '../../graphql/queries';
import { ViewUserQuery, ViewUserQueryVariables } from '../../generated/graphql';
import { Container } from '../../Atoms';
import { LinkButton, Spinner } from '../../Molecules';

interface RouterProps {
  username: string;
}

export const ViewUser: React.FC<RouteComponentProps<RouterProps>> = props => {
  const { match } = props;
  const {
    params: { username },
  } = match;
  const { user } = useUser();

  const { data, loading, error } = useQuery<ViewUserQuery, ViewUserQueryVariables>(VIEW_USER, {
    variables: { username },
  });

  if (loading) return <Spinner />;
  if (error) return <h1>ERROR</h1>;
  if (!data.viewUser) return <Redirect to="/notFound" />;

  const { viewUser: buddy } = data!;

  const name = buddy.firstName && buddy.lastName ? `${buddy.firstName} ${buddy.lastName}` : buddy.username;

  return (
    <>
      <Helmet>
        <title>{name} | Coworkingbuddies</title>
      </Helmet>
      <Container>
        <Flex direction="column">
          <Flex width="100%" align={['center', 'flex-start']} direction={{ base: 'column', sm: 'row' }}>
            <Flex>
              <Avatar size="2xl" name={name} src={buddy.profileImage} />
            </Flex>
            <Flex direction="column" ml={6}>
              <Flex align="center" direction={['column', 'row']} mb={2}>
                <Heading size="xl" mr={4}>
                  {name}
                </Heading>
                {!!user && buddy.username === user.username && (
                  <LinkButton to="/account" size="sm" variant="outline">
                    Edit profile
                  </LinkButton>
                )}
              </Flex>
              <Flex mb={4}>
                <Flex align="center" mr={4} color="gray.600">
                  <Text as="span" fontWeight="bold" mr={2}>
                    {buddy.location}
                  </Text>
                  <Box as={FiGlobe} />
                </Flex>
                <Flex align="center" color="gray.600">
                  <Text as="span" fontWeight="bold" mr={2}>
                    {buddy.profession}
                  </Text>
                  <Box as={FiBriefcase} />
                </Flex>
              </Flex>
              <Text mb={8}>{buddy.bio}</Text>
              {!!buddy.interestedIn && (
                <Flex mb={8} wrap="wrap">
                  {buddy.interestedIn.map(interest => (
                    <Tag key={interest} mr={2} mb={2}>
                      {interest}
                    </Tag>
                  ))}
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Tabs>
          <TabList>
            <Tab>Meetings ({buddy.meetings.length})</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <SimpleGrid marginY={2} columns={[2, 4]} spacing={10}>
                {buddy.meetings.map(meeting => (
                  <Link to={`/meeting/${meeting.id}`} key={meeting.id}>
                    <Box rounded="md" bg="gray.200" boxShadow="sm">
                      <Image src={meeting.pictureUrl} width="100%" />
                      <Box p={5}>
                        <Heading as="h4" size="md">
                          {meeting.title}
                        </Heading>
                      </Box>
                    </Box>
                  </Link>
                ))}
              </SimpleGrid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </>
  );
};
