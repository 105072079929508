import React from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
  FormLabelProps,
  FormErrorMessageProps,
  TextareaProps,
} from '@chakra-ui/react';
import { ErrorMessage, Field, FieldAttributes, FieldProps } from 'formik';

interface IFormTextareaInput {
  name: string;
  formLabel?: string;
  helperText?: string;
  isRequired?: boolean;
  formLabelProps?: FormLabelProps;
  formErrorMessageProps?: FormErrorMessageProps;
}

type FormTextareaProps = FieldAttributes<IFormTextareaInput & TextareaProps>;

export const FormTextarea: React.FC<FormTextareaProps> = ({
  name,
  formLabel,
  placeholder = 'Write something...',
  isRequired = false,
}) => {
  return (
    <Field name={name}>
      {({ form, field }: FieldProps) => (
        <FormControl isRequired={isRequired} isInvalid={!!form.touched[name] && !!form.errors[name]} mb={4}>
          {formLabel && <FormLabel htmlFor={name}>{formLabel}</FormLabel>}
          <Textarea {...field} placeholder={placeholder} />
          <ErrorMessage name={name} component={FormErrorMessage} />
        </FormControl>
      )}
    </Field>
  );
};
