import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Heading,
  Flex,
  Text,
} from '@chakra-ui/react';

import { IModalProps } from '../Providers';
import { LinkButton } from '../Molecules';

export const SignUpModal: React.FC<IModalProps<{}>> = ({ closeModal }) => {
  return (
    <Modal onClose={closeModal} isOpen={true} isCentered size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader />
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" align="center">
            <Heading marginBottom={4}>Become a buddy</Heading>
            <Text textAlign="center">
              We let people with laptops connect in real life to create new projects and meetings.
            </Text>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <LinkButton to="/register" colorScheme="teal" onClick={closeModal}>
            Join now!
          </LinkButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
