import React from 'react';
import * as Sentry from '@sentry/browser';
import { Heading, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { Container } from '../Atoms';

type ErrorComponentProps = {
  error: {
    message: string;
    stack: string;
  };
  info: {
    componentStack: string;
  };
};

type State = {
  hasError: boolean;
  error: {
    message: string;
    stack: string;
  };
  info: {
    componentStack: string;
  };
};

const ErrorComponent: React.FC<ErrorComponentProps> = ({ error, info }) => (
  <Container h="100%" d="flex" justifyContent="center" flexDir="column">
    <Heading as="h1" fontSize={['3rem', '8rem']} fontWeight="bolder" mb={24}>
      Something went wrong.
    </Heading>
    <Text fontSize="lg" mb={4}>
      Return to the home page to find what you are looking for.
    </Text>
    <Link to={'/meetings'} style={{ color: '#319795' }}>
      Go to homepage
    </Link>
  </Container>
);

export default class ErrorBoundary extends React.Component<{}, State> {
  state = {
    hasError: false,
    error: { message: '', stack: '' },
    info: { componentStack: '' },
  };

  // static getDerivedStateFromError = (error: any) => {
  //   return { hasError: true };
  // };a

  componentDidCatch = (error: any, info: any) => {
    this.setState({ error, info, hasError: true });
    Sentry.withScope(scope => {
      Sentry.captureException(error);
    });
  };

  render() {
    const { hasError, ...state } = this.state;

    const { children } = this.props;

    return hasError ? <ErrorComponent {...state} /> : children;
  }
}
