import * as React from 'react';
import { gql } from 'apollo-boost';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { Link as RLink, RouteComponentProps } from 'react-router-dom';
import {
  Heading,
  Flex,
  Text,
  useColorMode,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Container,
  Box,
  Link,
} from '@chakra-ui/react';

import { LoginMutationMutationVariables, LoginMutationMutation } from '../../generated/graphql';
import { LoginForm } from './components';

const loginMutation = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      path
      message
    }
  }
`;

interface FormValues {
  email: string;
  password: string;
}

export const Login: React.FC<RouteComponentProps<{}>> = props => {
  const client = useApolloClient();
  const [login] = useMutation<LoginMutationMutation, LoginMutationMutationVariables>(loginMutation);

  const {
    history,
    location: { state },
  } = props;

  const handleLogin = async (values: FormValues, setErrors: any) => {
    const { data } = await login({ variables: values });

    if (data && data.login) {
      const [err] = data.login;
      return setErrors({ [err.path]: err.message });
    }

    await client.resetStore();

    if (state && state.next) {
      return history.push(state.next);
    }

    return props.history.push('/meetings');
  };

  const { colorMode } = useColorMode();

  const colors = {
    text: { light: 'gray.800', dark: 'gray.50' },
    border: { light: 'gray.200', dark: 'whiteAlpha.300' },
  };

  return (
    <Box bg="gray.100" h="100%">
      <Container h="100%" pt={4}>
        <Flex justify="center" align="center" h="100%">
          <Flex
            maxWidth="40rem"
            width="100%"
            p={8}
            bg="white"
            direction="column"
            rounded="md"
            borderWidth={1}
            borderRadius={8}
            boxShadow="lg"
          >
            <Heading as="h3" size="lg" mb={8} color={colors.text[colorMode]}>
              Login
            </Heading>
            {(state || {}).fromLogin && (
              <Alert status="info" flexDirection="column" justifyContent="center" textAlign="center" mb={8}>
                <AlertIcon />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                  Almost done!
                </AlertTitle>
                <AlertDescription maxWidth="sm">
                  We need to confirm your email address. To complete the process, please click the link in the email we
                  just sent you.
                </AlertDescription>
              </Alert>
            )}
            <LoginForm handleLogin={handleLogin} />
            <Flex mt={6} direction="column">
              <Text color={colors.text[colorMode]}>
                Not registered with us yet?{' '}
                <Link as={RLink} color="blue.500" to="/register">
                  Sign up
                </Link>
              </Text>
              <Text color={colors.text[colorMode]}>
                Forgot you password?{' '}
                <Link as={RLink} color="blue.500" to="/forgot-password">
                  Change it
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};
