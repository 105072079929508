import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { RoutingProvider, UserProvider, ModalRenderer } from './Providers';
import { Drawer } from './Molecules';
import { ErrorBoundary } from './shared';

export const App = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <UserProvider>
          <Drawer />
          <RoutingProvider />
          <ModalRenderer />
        </UserProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};
