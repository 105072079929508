import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { Heading, Text, Button, useToast } from '@chakra-ui/react';

import { Container } from '../../Atoms';
import { SEND_FORGOT_PASSWORD_LINK } from '../../graphql/mutations';
import { ForgotPasswordMutation, ForgotPasswordMutationVariables } from '../../generated/graphql';
import { FormInput } from '../../shared/components';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
});

export const ForgotPassword: React.FC<{}> = () => {
  const toast = useToast();
  const [sendForgotPasswordLink] = useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(
    SEND_FORGOT_PASSWORD_LINK
  );

  const handleSendEmail = async (email: string) => {
    const {
      data: { forgotPassword },
    } = await sendForgotPasswordLink({ variables: { email } });

    if (!!forgotPassword) {
      toast({
        duration: 5000,
        isClosable: true,
        position: 'top-right',
        title: 'Check your email!',
        status: 'success',
      });
    } else {
      toast({
        duration: 5000,
        isClosable: true,
        position: 'top-right',
        title: 'Something went wrong!',
        status: 'error',
      });
    }

    return forgotPassword;
  };

  return (
    <Container>
      <Heading>Did you forget your password?</Heading>
      <Text>Enter your email address you're using for your account below and we will fix it together.</Text>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSendEmail(values.email);
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <form onSubmit={handleSubmit}>
            <FormInput isRequired name="email" type="email" placeholder="myemail@address.com" />
            <Button
              type="submit"
              size="lg"
              colorScheme="teal"
              isDisabled={isSubmitting || !isValid}
              isLoading={isSubmitting}
            >
              Reset it
            </Button>
          </form>
        )}
      </Formik>
    </Container>
  );
};
