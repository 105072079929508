import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { RouteComponentProps, Link as RLink } from 'react-router-dom';
import { Box, Center, Container, Flex, Heading, useColorMode, Link, Text } from '@chakra-ui/react';

import { REGISTER_MUTATION } from '../../graphql/mutations';
import { RegisterMutationVariables, RegisterMutation } from '../../generated/graphql';
import { RegisterForm } from './components/RegisterForm';

interface FormValues {
  email: string;
  username: string;
  password: string;
}

const colors = {
  text: { light: 'gray.800', dark: 'gray.50' },
  border: { light: 'gray.200', dark: 'whiteAlpha.300' },
};

export const Register: React.FC<RouteComponentProps> = props => {
  const [register] = useMutation<RegisterMutation, RegisterMutationVariables>(REGISTER_MUTATION);

  const handleRegister = async (values: FormValues, setErrors: any) => {
    const { data } = await register({
      variables: {
        data: values,
      },
    });

    if (data && data.register) {
      const [err] = data.register;
      return setErrors({ [err.path]: err.message });
    }

    props.history.push('/login', { fromLogin: true });
  };

  const { colorMode } = useColorMode();

  return (
    <Box bg="gray.100" h="100%">
      <Container h="100%" pt={4}>
        <Center h="100%">
          <Flex
            maxWidth="40rem"
            width="100%"
            p={8}
            bg="white"
            direction="column"
            rounded="md"
            borderWidth={1}
            borderRadius={8}
            boxShadow="lg"
          >
            <Heading as="h3" size="lg" mb={8} color={colors.text[colorMode]}>
              Register
            </Heading>
            <RegisterForm handleRegister={handleRegister} />
            <Flex mt={6} direction="column">
              <Text>
                Or, just{' '}
                <Link as={RLink} color="blue.500" to="/login">
                  login
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Center>
      </Container>
    </Box>
  );
};
