import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { logoutMutation } from '../../graphql/mutations';
import { useAsyncEffect } from '../../hooks';
import { UserContext } from '../../contexts';

export const Logout: React.FC<RouteComponentProps> = props => {
  const [logout] = useMutation(logoutMutation);
  const client = useApolloClient();
  const { setUser } = React.useContext(UserContext);

  useAsyncEffect(async () => {
    await logout();
    await client.clearStore();
    await setUser(null);
    await props.history.push('/login');
  }, []);

  return null;
};
