import * as React from "react";
import { useAsyncEffect } from "./useAsyncEffect"

export const useFetch = (url: string) => {
    const [data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    useAsyncEffect(async () => {
        const response = await fetch(url);
        const { data } = await response.json();
        const [item] = data;
        setData(item);
        setLoading(false);
    }, []);

    return { data, loading };
};