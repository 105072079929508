import * as React from 'react';
import { LanguageContext } from '../../contexts/Lang';
import { ILangContext } from '../../types';
import sv from '../../Translations/sv.json';
import en from '../../Translations/en.json';

export default class LanguageProvider extends React.PureComponent<{}, ILangContext> {

  state: ILangContext = {
    lang: document.querySelector("html")!.getAttribute("lang")!,
    setLang: (lang: string) => {
      document.querySelector("html")!.setAttribute("lang", lang);
      this.setState({ lang })
    },
    translations: {
      sv,
      en
    }
  }

  render() {
    return (
      <LanguageContext.Provider value={this.state}>
        {this.props.children}
      </LanguageContext.Provider>
    );
  }
}