import React, { useState } from 'react';
import {
  PopoverProps,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Stack,
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
  Spinner,
  List,
  ListItem,
} from '@chakra-ui/react';
import FocusLock from 'react-focus-lock';
import { useUser } from '../../contexts';
import { useLazyQuery } from '@apollo/react-hooks';
import { SearchUsersQuery, SearchUsersQueryVariables } from '../../generated/graphql';
import { SEARCH_USERS_USERNAME } from '../../graphql/queries';
import debounce from 'lodash.debounce';

type Props = {
  triggerLabel: string;
  push: (obj: any) => void;
  addedUsernames: any;
} & Omit<PopoverProps, 'children'>;

export const BuddyPopover: React.FC<Props> = ({
  triggerLabel,
  initialFocusRef,
  push,
  addedUsernames,
  ...popoverProps
}) => {
  const [username, setUsername] = useState('');
  const { user: loggedInUser } = useUser();
  const [search, { data, loading }] = useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SEARCH_USERS_USERNAME);

  const onChange = (e: any) => {
    const value = e.target.value;
    setUsername(value);
    handleFilter(value);
  };

  const handleFilter = debounce((val: string) => {
    search({ variables: { username: val, limit: 5, offset: 0 } });
  }, 500);

  return (
    <Popover initialFocusRef={initialFocusRef} placement="top-start" closeOnBlur={false} {...popoverProps}>
      <PopoverTrigger>
        <Button variant="link">{triggerLabel}</Button>
      </PopoverTrigger>
      <PopoverContent zIndex={4} p={5}>
        <FocusLock returnFocus persistentFocus={false}>
          <PopoverArrow bg="white" />
          <PopoverCloseButton />
          <Stack spacing={4}>
            <FormLabel htmlFor={'username'}>Search with username</FormLabel>
            <InputGroup>
              <Input
                id="username"
                ref={initialFocusRef as React.MutableRefObject<any>}
                value={username}
                placeholder="Buddy's username"
                onChange={onChange}
              />
              {loading && <InputRightElement children={<Spinner size="sm" />} />}
            </InputGroup>

            {!!data && !!data.searchUsers.length && (
              <List spacing={4}>
                {data.searchUsers.map(user => {
                  return (
                    <ListItem
                      key={user.username}
                      _hover={{
                        cursor: 'pointer',
                      }}
                    >
                      {/* TODO: MAKE THE BUDDYS SCROLLABLE HORIZONTALLY. IT WILL LOOOK NICE ON MOBILE */}
                      <Button
                        type="button"
                        variant="link"
                        isDisabled={[...addedUsernames, loggedInUser.username].includes(user.username)}
                        onClick={() => {
                          setUsername('');
                          push(user);
                        }}
                      >
                        Add @{user.username}
                      </Button>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Stack>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};
