import Axios from 'axios';
import { format } from 'date-fns';

const uploadToS3 = async (picture: any, signedRequest: string) => {
  const options = {
    headers: {
      'Content-Type': picture.type,
    },
  };
  await Axios.put(signedRequest, picture, options);
};

const formatFileName = (filename: string) => {
  const date = format(new Date(), 'yyyyMMdd');
  const randomString = Math.random()
    .toString(36)
    .substring(2, 7);
  const cleanFileName = filename.toLowerCase().replace(/[^a-z0-9]/g, '-');
  const newFilename = `images/sessions/${date}-${randomString}-${cleanFileName}`;
  return newFilename.substring(0, 60);
};

export { uploadToS3, formatFileName };
