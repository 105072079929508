import { gql } from 'apollo-boost';
import { ERROR_ATTRIBUTES, USER_ATTRIBUTES } from '../fragments';

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UserInput!) {
    updateUser(input: $input) {
      ... on User {
        ...userResults
      }
      ... on Error {
        ...errorResults
      }
    }
  }
  ${USER_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`;

export const CONFIRM_BUDDY = gql`
  mutation ConfirmUser($token: String!) {
    confirmUser(token: $token)
  }
`;
