import React from 'react';
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import { useMedia } from 'react-media';
import { FiMenu } from 'react-icons/fi';
import { Link as RLink } from 'react-router-dom';

import { GLOBAL_MEDIA_QUERIES } from '../../../constants';
import { LinkButton } from '../../../Molecules';
import Logo from '../../../assets/images/cwb_logo.png';
import { User } from '../../../types';
import { NavbarModal } from './components';

export const Navbar = ({ user }: { user: User }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isSmallScreen = useMedia({ query: GLOBAL_MEDIA_QUERIES['small'] });

  return (
    <Box as="header" borderColor="gray.200" borderBottomStyle="solid" borderBottomWidth="1px" bg="white">
      <Box px={8} py={4} maxW="7xl" marginInline="auto">
        <Flex as="nav" justify="space-between">
          <Flex align="center">
            <Image src={Logo} objectFit="cover" h="55px" w="auto" />
            {!isSmallScreen && (
              <HStack marginInlineStart={8} spacing={4}>
                <Link as={RLink} fontWeight="bold" to="/meetings">Meetings</Link>
              </HStack>
            )}
          </Flex>
          <Flex align="center">
            <HStack marginInlineStart={8} spacing={4}>
              {isSmallScreen ? (
                <IconButton
                  variant="ghost"
                  aria-label=""
                  fontSize="2xl"
                  color="gray.600"
                  p={1}
                  icon={<FiMenu />}
                  onClick={onOpen}
                />
              ) : (
                <>
                  {user ? (
                    <>
                      <LinkButton to="/create-meeting" colorScheme="blue" rounded="full" marginInlineStart={8}>
                        Create session
                      </LinkButton>
                      <Menu>
                        <MenuButton as={Button} rounded={'full'} variant={'link'} cursor={'pointer'}>
                          <Avatar size="sm" src={user.profileImage} />
                        </MenuButton>
                        <MenuList>
                          <MenuItem as={RLink} to="/account" color="blue.400">
                            Profile
                          </MenuItem>
                          <MenuItem as={RLink} to="/logout" color="blue.400">
                            Logout
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </>
                  ) : (
                    <>
                      {' '}
                      <Link as={RLink} to="/login" fontWeight="bold">
                        Login
                      </Link>
                      <LinkButton to="/register" colorScheme="blue" rounded="full" marginInlineStart={8}>
                        Sign up
                      </LinkButton>
                    </>
                  )}
                </>
              )}
            </HStack>
          </Flex>
        </Flex>
      </Box>
      <NavbarModal isOpen={isOpen} onClose={onClose} user={user} />
    </Box>
  );
};
