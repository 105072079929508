import { gql } from 'apollo-boost';

import { ERROR_ATTRIBUTES } from '../fragments';

export const CREATE_PROJECT = gql`
  mutation CreateProject($data: CreateProjectInput!) {
    createProject(data: $data) {
      ... on Project {
        id
        title
        description
        availablePositions
        members {
          user {
            username
          }
        }
        managers {
          user {
            username
          }
        }
      }

      ... on Error {
        ...errorResults
      }
    }
  }
  ${ERROR_ATTRIBUTES}
`;
