import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { RouteComponentProps } from 'react-router';
import { useToast, Heading, Button } from '@chakra-ui/react';

import { CHANGE_PASSWORD } from '../../graphql/mutations';
import { Container } from '../../Atoms';
import { ChangePasswordMutation, ChangePasswordMutationVariables } from '../../generated/graphql';
import { FormInput } from '../../shared/components';

const validationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(6, 'Must be at least 6 characters')
    .max(200)
    .required(),
});

type Props = RouteComponentProps<{ token: string }>;

export const ChangePassword: React.FC<Props> = ({ match, history }) => {
  const [forgotPassword] = useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(CHANGE_PASSWORD);
  const toast = useToast();

  const handleNewPassword = async (newPassword: string) => {
    const {
      data: { changePassword },
    } = await forgotPassword({
      variables: {
        data: {
          password: newPassword,
          token: match.params.token,
        },
      },
    });

    if (!!changePassword) {
      toast({
        duration: 5000,
        isClosable: true,
        position: 'top-right',
        title: 'Your password has been changed!',
        description: 'Try logging in with your new password',
        status: 'success',
      });
    } else {
      toast({
        duration: 5000,
        isClosable: true,
        position: 'top-right',
        title: 'Something went wrong!',
        status: 'error',
      });
    }

    return changePassword;
  };

  return (
    <Container>
      <Heading>Enter new password</Heading>
      <Formik
        initialValues={{
          newPassword: '',
        }}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={async (values, { setSubmitting }) => {
          await handleNewPassword(values.newPassword);
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <form onSubmit={handleSubmit}>
            <FormInput name="newPassword" type="password" placeholder="Secret stuff" />
            <Button
              type="submit"
              size="lg"
              colorScheme="teal"
              isDisabled={isSubmitting || !isValid}
              isLoading={isSubmitting}
            >
              Change it
            </Button>
          </form>
        )}
      </Formik>
    </Container>
  );
};
