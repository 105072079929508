import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { addMinutes, roundToNearestMinutes } from 'date-fns';
import { Flex, Heading, Divider, useToast } from '@chakra-ui/react';
import { RouteComponentProps } from 'react-router';

import { createMeetingMutation, SIGN_S3 } from '../../graphql/mutations';
import {
  CreateMeetingMutation,
  CreateMeetingMutationVariables,
  SignS3Mutation,
  SignS3MutationVariables,
} from '../../generated/graphql';
import { MeetingForm } from '../../Organisms';
import { toFormatedDate, toFormatedTime, formatFileName, uploadToS3 } from '../../utils';
import { useUser } from '../../contexts';

export interface MeetingFormValues {
  title: string;
  description: string;
  latitude: number;
  longitude: number;
  tags: string[];
  picture: any | null;
  startingDate: Date;
  startingTime: Date;
  endingTime: Date;
  tmp: string;
  isPublic: boolean;
  attendees: any[];
  tmpIsOnline: boolean;
  onlineLink: string;
}

const initialValues: MeetingFormValues = {
  title: '',
  description: '',
  latitude: 0,
  longitude: 0,
  tags: [],
  picture: null,
  startingDate: new Date(),
  startingTime: addMinutes(roundToNearestMinutes(new Date(), { nearestTo: 30 }), 30),
  endingTime: addMinutes(roundToNearestMinutes(new Date(), { nearestTo: 30 }), 60),
  tmp: '',
  tmpIsOnline: false,
  onlineLink: '',
  isPublic: true,
  attendees: [],
};

type Props = RouteComponentProps;

export const CreateMeeting: React.FC<Props> = ({ history: { push } }) => {
  const toast = useToast();
  const { user } = useUser();

  const [signS3] = useMutation<SignS3Mutation, SignS3MutationVariables>(SIGN_S3);
  const [createMeeting] = useMutation<CreateMeetingMutation, CreateMeetingMutationVariables>(createMeetingMutation);

  const handleCreateMeeting = async (values: MeetingFormValues) => {
    const {
      startingTime: st,
      endingTime: et,
      startingDate: sd,
      picture,
      tmp,
      tmpIsOnline,
      attendees,
      ...newVals
    } = values;
    let outerUrl = '';

    console.log('newVals', newVals);

    if (picture) {
      const res = await signS3({
        variables: {
          fileName: formatFileName(picture.name),
          fileType: picture.type,
        },
      });

      const { signedRequest, url } = res.data.signS3;
      outerUrl = url;

      await uploadToS3(picture, signedRequest);
    }

    const startingDate = new Date(`${toFormatedDate(sd)} ${toFormatedTime(st)}`);
    const endingDate = new Date(`${toFormatedDate(sd)} ${toFormatedTime(et)}`);

    const {
      data: {
        createMeeting: [c],
      },
    } = await createMeeting({
      variables: {
        data: {
          ...newVals,
          startingDate,
          endingDate,
          pictureUrl: outerUrl,
          attendees: attendees.map(a => a.username).concat(user.username), // will grab this id when creating a meeting to put the user as owner
        },
      },
    });

    if (c.__typename === 'Error') {
      toast({
        title: 'Uho',
        description: c.message,
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
    } else {
      push(`meeting/${c.id}`);
    }
  };

  return (
    <Flex m={4} justifyContent="center" flexDirection="column">
      <Heading textAlign="center">New Coworking Session</Heading>
      <Divider my={4} />
      <MeetingForm upsertMeeting={handleCreateMeeting} initialValues={initialValues} />
    </Flex>
  );
};
