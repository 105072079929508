import React, { useState } from 'react';
import { FieldAttributes, useFormikContext } from 'formik';
import { Flex, FormLabel, Box, Text } from '@chakra-ui/react';

import { PlacesAutocomplete } from '../Molecules/PlacesAutoComplete';
import { MapWithAMarker, DefaultCenter } from '../Molecules/MapWithMarker';

type Props = FieldAttributes<{}> & { defaultValue?: string; formLabel?: string; isMandatory?: boolean };

export const LocationFieldWithMap: React.FC<Props> = ({ defaultValue, formLabel, isMandatory, ...rest }) => {
  const { setValues, values } = useFormikContext<any>();

  const [defaultCenter, setDefaultCenter] = useState<DefaultCenter | null>(() =>
    values.location ? { lat: values.location.latitude, lng: values.location.longitude } : null
  );

  const getCoordinates = (coordinates: { lat: number; lng: number }) => {
    setValues({
      ...values,
      latitude: coordinates.lat,
      longitude: coordinates.lng,
    });
    setDefaultCenter({
      lat: coordinates.lat,
      lng: coordinates.lng,
    });
  };

  return (
    <Flex direction="column" mb={6}>
      {formLabel && (
        <FormLabel>
          {formLabel}
          {isMandatory && (
            <Text as="span" color="red.500" ml={2}>
              *
            </Text>
          )}
        </FormLabel>
      )}
      <PlacesAutocomplete defaultValue={rest.value} getCoordinates={getCoordinates} />
      {defaultCenter && (
        <MapWithAMarker
          defaultCenter={defaultCenter}
          lat={defaultCenter.lat}
          lng={defaultCenter.lng}
          containerElement={<Box height="400px" />}
          mapElement={<Box height="100%" rounded="lg" />}
        />
      )}
    </Flex>
  );
};
