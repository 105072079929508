import React from 'react';
import { Box, Text, Flex, Heading, Button, Stack, useColorMode, Divider } from '@chakra-ui/react';
import { isPast } from 'date-fns';
import { isWithinInterval } from 'date-fns/esm';

import { Container, BlinkingDot } from '../../../Atoms';
import { ViewMeetingQuery } from '../../../generated/graphql';
import { viewMeetingColors } from '../index';
import { formatMeetingDate } from '../../../utils';

type Props = {
  meeting: ViewMeetingQuery['viewMeeting'];
  isOwnMeeting: boolean;
  handleAssignToMeeting: (alreadySignedUp: boolean, isInvited: boolean) => Promise<void>;
  alreadySignedUp: boolean;
  isInvited: boolean;
};

const getAttendBtnTxt = (isPastMeeting: boolean, alreadySignedUp: boolean, isInvited: boolean) => {
  if (isPastMeeting) {
    return 'Past co-working session';
  } else if (isInvited) {
    return 'Accept invitation';
  } else if (alreadySignedUp) {
    return 'Leave';
  } else if (!alreadySignedUp) {
    return 'Attend';
  }
};

export const StickyMeetingFooter: React.FC<Props> = ({
  meeting,
  isOwnMeeting,
  handleAssignToMeeting,
  alreadySignedUp,
  isInvited,
}) => {
  const { colorMode } = useColorMode();

  const color = {
    default: {
      dark: 'gray.700',
      light: 'white',
    },
  };

  const isPastMeeting = isPast(meeting.endingDate);
  const isOngoing = isWithinInterval(new Date(), { start: meeting.startingDate, end: meeting.endingDate });

  const btnText = getAttendBtnTxt(isPastMeeting, alreadySignedUp, isInvited);
  const buddiesText = isPastMeeting ? 'buddie(s) went' : isOngoing ? 'buddie(s) working right now' : 'buddie(s) coming';
  const acceptedAttendeesAmount = meeting.attendees.filter(attendee => attendee.hasAccepted).length;

  return (
    <Box
      width="100%"
      backgroundColor={color.default[colorMode]}
      boxShadow="outline"
      position="sticky"
      paddingY={[4, 8]}
      bottom="0"
    >
      <Container>
        <Flex justify="space-between" align="center">
          <Stack pb={4}>
            <Flex>
              <Heading as="h5" size="sm" color={viewMeetingColors.heading[colorMode]}>
                {acceptedAttendeesAmount} {buddiesText}
              </Heading>
              {isOngoing && (
                <>
                  <Divider orientation="vertical" />
                  <BlinkingDot />
                </>
              )}
            </Flex>
            <Flex>
              <Text color={viewMeetingColors.text[colorMode]}>
                {formatMeetingDate({ startingDate: meeting.startingDate, endingDate: meeting.endingDate })}
              </Text>
            </Flex>
          </Stack>
          <Button
            colorScheme="teal"
            size="lg"
            isDisabled={isOwnMeeting || isPastMeeting}
            onClick={() => handleAssignToMeeting(alreadySignedUp, isInvited)}
          >
            {btnText}
          </Button>
        </Flex>
      </Container>
    </Box>
  );
};
