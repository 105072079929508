import React, { createContext, useState, useContext } from 'react';

import { modals } from './modals';

type ActiveModal = {
  modalName: string;
  modalProps: any;
};

interface IModalContext {
  activeModal: ActiveModal;
  openModal: (modalName: string, modalProps?: Object) => void;
  closeModal: (modalName: string) => void;
}

export interface IModalProps<T extends { [prop: string]: any }> {
  activeModal: ActiveModal;
  closeModal: () => void;
  modalProps: T;
}

const ModalContext = createContext<IModalContext | null>(null as any);

const ModalProvider: React.FC<{}> = ({ children }) => {
  const [activeModal, setActiveModal] = useState({ modalName: '', modalProps: {} });

  const openModal = (modalName: string, modalProps: Object = {}) => {
    setActiveModal({ modalName, modalProps });
  };
  const closeModal = () => {
    setActiveModal({ modalName: '', modalProps: {} });
  };

  const value = {
    activeModal,
    openModal,
    closeModal,
  };

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

const ModalRenderer = () => (
  <ModalContext.Consumer>
    {({ activeModal, closeModal, ...rest }) => {
      if (!!activeModal.modalName) {
        const { modalName, modalProps } = activeModal;
        const { component: Component } = modals[modalName];

        return <Component key={modalName} modalProps={modalProps} closeModal={() => closeModal(modalName)} {...rest} />;
      }
      return null;
    }}
  </ModalContext.Consumer>
);

const useModal = () => {
  const modalContext = useContext(ModalContext);
  if (!modalContext) {
    throw new Error('useModal must be within ModalProvider');
  }
  return modalContext;
};

export { ModalProvider, useModal, ModalRenderer };
