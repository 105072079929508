/** @jsx jsx */

import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { keyframes, css, jsx } from '@emotion/core';

const blink = keyframes`
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

type Props = {
  speed?: number;
} & BoxProps;

export const BlinkingDot: React.FC<Props> = ({ speed = 1.25, ...rest }) => (
  <Box
    bg="red.400"
    size="20px"
    rounded="full"
    {...rest}
    css={css`
      animation: ${blink} ${speed}s ease infinite;
    `}
  />
);
