import { gql } from 'apollo-boost';

export const USER_ATTRIBUTES = gql`
  fragment userResults on User {
    username
    firstName
    lastName
    profileImage
    bio
    interestedIn
    location
    profession
  }
`;
