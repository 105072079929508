import * as React from 'react';
import { Box, Image, Stack, StackDivider } from '@chakra-ui/react';
import Logo from '../../../assets/images/cwb_logo.png';

import { Copyright, LinkGrid, SocialMediaLinks, SubscribeForm } from './components';

export const Footer = () => (
  <Box as="footer" role="contentinfo" bg="gray.100">
    <Box mx="auto" maxW="7xl" py="12" px={{ base: '4', md: '8' }}>
      <Stack spacing="10" divider={<StackDivider />}>
        <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '10', lg: '28' }}>
          <Box flex="1">
            <Image src={Logo} w="125px" h="auto" />
          </Box>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '10', md: '20' }}>
            <LinkGrid spacing={{ base: '10', md: '20', lg: '28' }} flex="1" />
            {/* <SubscribeForm width={{ base: 'full', md: 'sm' }} /> */}
          </Stack>
        </Stack>
        <Stack direction={{ base: 'column-reverse', md: 'row' }} justifyContent="space-between" alignItems="center">
          <Copyright />
          <SocialMediaLinks />
        </Stack>
      </Stack>
    </Box>
  </Box>
);
