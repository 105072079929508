export const parseQueryString = (query: string) => {
  return query
    .substr(1)
    .split('&')
    .reduce((acc: any, next: string) => {
      const [key, value] = next.split('=');
      acc[key] = value;
      return acc;
    }, {});
};

export const scrollToElement = (id: string) => {
  document.querySelector(id)!.scrollIntoView({ behavior: 'smooth' });
};

export const chunk = <T>(array: Array<T>, chunkSize: number): Array<Array<T>> => {
  const chunked: Array<Array<T>> = [];
  let index: number = 0;

  while (index < array.length) {
    chunked.push(array.slice(index, index + chunkSize));
    index += chunkSize;
  }

  return chunked;
};
