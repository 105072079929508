import React from 'react';
import * as yup from 'yup';
import { Button } from '@chakra-ui/react';

import { Formik } from 'formik';
import { FormInput } from '../../../shared/components';

interface FormValues {
  email: string;
  password: string;
}

const initialValues: FormValues = {
  email: '',
  password: '',
};

const emailNotLongEnough = 'Email must be at least 3 characters';
const emailNotValid = 'Email must be a valid email';
const passwordNotLongEnough = 'Password must be at least 3 characters';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .min(3, emailNotLongEnough)
    .max(255)
    .email(emailNotValid)
    .required(),
  password: yup
    .string()
    .min(3, passwordNotLongEnough)
    .max(255)
    .required(),
});

type Props = {
  handleLogin: (values: FormValues, setErrors: any) => Promise<any>;
};

export const LoginForm: React.FC<Props> = ({ handleLogin }) => (
  <Formik
    initialValues={initialValues}
    validateOnChange={false}
    validateOnBlur={true}
    validationSchema={validationSchema}
    onSubmit={(values, actions) => {
      handleLogin(values, actions.setErrors);
      actions.setSubmitting(false);
    }}
  >
    {({ isSubmitting, handleSubmit }) => (
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <FormInput isRequired name="email" variant="flushed" formLabel="Email" />
        <FormInput isRequired name="password" variant="flushed" type="password" formLabel="Password" />
        <Button mt={4} colorScheme="teal" isLoading={isSubmitting} type="submit" isDisabled={isSubmitting}>
          Submit
        </Button>
      </form>
    )}
  </Formik>
);
