import React from 'react';
import { useField, FieldAttributes } from 'formik';
import { CommonProps } from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import { Box, FormLabel, Text, FormErrorMessage, FormHelperText } from '@chakra-ui/react';

type SelectTypes = CommonProps<{}> & CreatableProps<{}>;
type Props = FieldAttributes<SelectTypes> & {
  noOptionsMessage?: ((obj: { inputValue: string }) => string | null) | undefined;
  formLabel?: string;
  isMandatory?: boolean;
  helperText?: string;
};

export const MultiSelectInput: React.FC<Props> = ({
  name,
  options,
  children,
  formatCreateLabel,
  noOptionsMessage,
  formLabel,
  helperText,
  isMandatory = false,
  ...rest
}) => {
  const [field, meta, helpers] = useField<Array<any>>(name);

  const errorTxt = meta.error ? meta.error : '';

  const selectedValues = field.value.map(value => ({
    value,
    label: value,
  }));

  const onChange = (newOptions: Array<any> | null) => {
    if (!newOptions) {
      helpers.setValue([]);
    } else {
      helpers.setValue(newOptions.map((v: any) => v.value));
    }
  };

  return (
    <Box>
      {formLabel && (
        <FormLabel>
          {formLabel}
          {isMandatory && (
            <Text as="span" color="red.500" ml={2}>
              *
            </Text>
          )}
        </FormLabel>
      )}
      <CreatableSelect
        options={options}
        {...field}
        value={selectedValues}
        // @ts-ignore
        onChange={onChange}
        formatCreateLabel={formatCreateLabel}
        isMulti={rest.isMulti}
        placeholder={rest.placeholder}
        noOptionsMessage={noOptionsMessage}
      />
      {errorTxt && <FormErrorMessage>{errorTxt}</FormErrorMessage>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </Box>
  );
};
