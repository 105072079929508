import ReactGA from 'react-ga';
import { Location, History } from 'history';

import { excludedGAPaths } from '../constants';

export const trackPageView = (location: Location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
};

export const initGA = (history: History) => {
  trackPageView(history.location);
  history.listen(trackPageView);
};

export const canTrackPath = (pathname: string) =>
  !excludedGAPaths.some(excludedGAPath => pathname.includes(excludedGAPath));
