import React, { useEffect } from 'react';
import { Flex, Heading, Text, Stack } from '@chakra-ui/react';

import { Container } from '../../Atoms';
import { LinkButton, Spinner } from '../../Molecules';
import { RouteComponentProps } from 'react-router';
import { useMutation } from '@apollo/react-hooks';
import { CONFIRM_BUDDY } from '../../graphql/mutations';
import { ConfirmUserMutationVariables, ConfirmUserMutation } from '../../generated/graphql';
import { useAsyncEffect } from '../../hooks';

type Props = RouteComponentProps<{ token: string }>;

export const ConfirmBuddy: React.FC<Props> = ({ match: { params } }) => {
  const [confirmBuddy, { data, loading }] = useMutation<ConfirmUserMutation, ConfirmUserMutationVariables>(
    CONFIRM_BUDDY
  );

  useAsyncEffect(async () => {
    await confirmBuddy({
      variables: {
        token: params.token,
      },
    });
  }, []);

  if (loading) return <Spinner />;

  return (
    <Container height="100%">
      <Flex direction="column" align="center" height="100%" justify="center">
        <Stack spacing={2} mb={4} align="center">
          <Heading>Confirming your account...</Heading>
          <Text textAlign="center">
            {!!data && data.confirmUser ? 'You can close this tab' : 'Something went wrong'}
          </Text>
        </Stack>
      </Flex>
    </Container>
  );
};
